import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useQueryString from "../hooks/useQueryString";
import { redirectToPreview } from "View/PreviewModelViewer";
import firebase from "firebase/compat/app";

const analytics = firebase.analytics();

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Global query string file pipe
 */
export default function Pipe() {
  const navigate = useNavigate();
  const { file, name } = useQueryString<{ file?: string; name?: string }>();

  useEffect(() => {
    if (file) {
      (async () => {
        console.info("Retrieving file from pipe", file);

        if (isSafari) {
          window.alert(
            "Safari does not support local file loading. Please set chrome as your default browser, or export a GLTF to drag and drop."
          );
        }

        try {
          const response = await fetch(file);
          const blob = await response.blob();
          const fileData = new File([blob], name ?? "", {
            type: response.headers.get("Content-Type") as string,
          });

          analytics.logEvent("Pipe File", {
            type: fileData.type,
            size: fileData.size,
            name: fileData.name,
          });
          redirectToPreview(fileData, (uri) => navigate(uri));
        } catch (err) {
          console.error("Error reading from pipe", err);
        }
      })();
    }
  }, [file, navigate, name]);

  return null;
}
