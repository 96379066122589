import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./Auth";
import { AuthProvider, RouteAnalytics } from "./Auth";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./Theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Logger from "./Logger";
import { AlertsProvider } from "Alerts";

createRoot(document.getElementById("root")!).render(
  <Logger>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AuthProvider>
          <AlertsProvider>
            <Router>
              <RouteAnalytics />
              <App />
            </Router>
          </AlertsProvider>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Logger>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
