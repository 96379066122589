import { useEffect, useState } from "react";

// Global local storage access
const handlers: Record<string, ((value: string) => any)[]> = {};

function setLocalStorageValue(id: string, value: string) {
  localStorage.setItem(id, value);
  handlers[id]?.forEach((cb) => cb(value));
}

function registerCallback(id: string, callback: (value: string) => any) {
  if (handlers[id] == null) handlers[id] = [];
  handlers[id].push(callback);

  return () => {
    handlers[id].splice(handlers[id].indexOf(callback), 1);
    if (handlers[id].length === 0) delete handlers[id];
  };
}

export function usePreference(id: string, defaultValue: string) {
  const [value, setValue] = useState<string>(
    localStorage.getItem(id) ?? defaultValue
  );

  useEffect(() => {
    setLocalStorageValue(id, value);
  }, [value, id]);

  useEffect(() => {
    return registerCallback(id, (v) => {
      setValue(v);
    });
  }, [id]);

  return { value: value, setValue: setValue };
}
