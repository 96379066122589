import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { Done, Warning } from "@mui/icons-material";
import { useMemo, useState } from "react";
import {
  BufferGeometry,
  Object3D,
  Material,
  Mesh,
  Texture,
  Box3,
  LineSegments,
  Line,
  Points,
} from "three";
import { logEvent } from "Auth";

export const MAX_MODEL_SIZE_BYTES = 25 * 1024 * 1024;

const guide = {
  size: {
    recommended: (MAX_MODEL_SIZE_BYTES * 3) / 4,
    max: MAX_MODEL_SIZE_BYTES,
  },
  objects: {
    max: 5000,
    recommended: 1000,
  },
  triangles: {
    max: 1000000,
    recommended: 500000,
  },
  textures4k: {
    max: 8,
    recommended: 4,
  },
  materials: {
    max: 150,
    recommended: 100,
  },
  bounds: {
    min: 0.001,
    recommendedMin: 0.5,
    max: 10000,
    recommendedMax: 500,
  },
};

export default HealthWarningDialog;
export function HealthWarningDialog({
  scene,
  size,
  enabled,
  bounds,
}: {
  scene: Object3D;
  size?: number;
  enabled: boolean;
  bounds: Box3;
}) {
  const stats = useMemo(() => {
    const res: ModelStats = getStats(scene, size ?? -1, bounds);
    console.info("Model Statistics", res);
    logEvent("Stats", res);
    return res;
  }, [scene, size, bounds]);

  const [visible, setVisible] = useState<boolean>(true);
  const required = useMemo(
    () =>
      stats.objects > guide.objects.recommended ||
      stats.triangles > guide.triangles.recommended ||
      stats.textures4k > guide.textures4k.recommended ||
      stats.materials > guide.materials.recommended ||
      stats.size > guide.size.max ||
      stats.bounds < guide.bounds.recommendedMin ||
      stats.bounds > guide.bounds.recommendedMax,
    [stats]
  );

  if (!enabled) return null;

  return (
    <Dialog open={visible && required}>
      <DialogTitle>Model Health</DialogTitle>
      <DialogContent>
        The health of this model needs your attention. It is recommended that
        you adjust your model and try publishing again.
        <Box component="div" sx={styles.content}>
          {stats.size > 0 && (
            <HealthLabel
              value={stats.size / 1024 / 1024}
              recommendedMax={guide.size.recommended / 1024 / 1024}
              max={guide.size.max / 1024 / 1024}
              label={"File size"}
              info={`This model is ${(stats.size / 1024 / 1024).toFixed(
                2
              )}MB. You can preview the model, but it must be under ${(
                guide.size.max /
                1024 /
                1024
              ).toFixed(0)}MB to publish.`}
            />
          )}
          <HealthLabel
            value={stats.bounds}
            max={guide.bounds.max}
            recommendedMax={guide.bounds.recommendedMax}
            min={guide.bounds.min}
            recommendedMin={guide.bounds.recommendedMin}
            label={"Dimension"}
            info={`This model is ${stats.bounds.toFixed(
              3
            )} meters in size. Are you sure you used the correct unit system?`}
          />
          <HealthLabel
            value={stats.objects}
            max={guide.objects.max}
            recommendedMax={guide.objects.recommended}
            label={"Objects"}
            info={`This model has ${stats.objects.toLocaleString()} objects. This number can be reduced by joining objects of the same material type. It's recommended to keep this under ${
              guide.objects.recommended
            }.`}
          />
          <HealthLabel
            value={stats.triangles}
            max={guide.triangles.max}
            recommendedMax={guide.triangles.recommended}
            label={"Triangles"}
            info={`This model has ${stats.triangles.toLocaleString()} triangles. This can be reduced by changing meshing parameters or decimating meshes. It's recommended to keep this under ${
              guide.triangles.recommended
            }.`}
          />
          <HealthLabel
            value={stats.textures4k}
            max={guide.textures4k.max}
            recommendedMax={guide.textures4k.recommended}
            label={"4K+ Textures"}
            info={`This model uses ${stats.textures4k} high resolution textures. This number can be reduced by using smaller images. It's recommended to keep this under ${guide.textures4k.recommended}.`}
          />
          <HealthLabel
            value={stats.materials}
            max={guide.materials.max}
            recommendedMax={guide.materials.recommended}
            label={"Materials"}
            info={`This model uses ${stats.materials} individual materials. You may want to consider using fewer materials to improve performance. It's recommended to keep this under ${guide.materials.recommended}.`}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setVisible(false)}>Acknowledge</Button>
      </DialogActions>
    </Dialog>
  );
}

export type ModelStats = {
  objects: number;
  triangles: number;
  vertices: number;
  materials: number;
  textures: number;
  textures4k: number;
  size: number;
  bounds: number;
};

export function getStats(scene: Object3D, size: number, bounds: Box3) {
  const stats: ModelStats = {
    objects: 0,
    triangles: 0,
    vertices: 0,
    materials: 0,
    textures: 0,
    textures4k: 0,
    bounds: bounds.max.clone().sub(bounds.min).length(),
    size: size,
  };

  const checked_materials: Record<string, boolean> = {};
  const checked_textures: Record<string, boolean> = {};

  const types = ["Mesh", "Line", "LineSegments", "Points"];
  scene.traverse((obj3d) => {
    if (types.includes(obj3d.type)) {
      const obj = obj3d as Mesh | Line | LineSegments | Points;
      stats.objects++;
      if (obj.geometry instanceof BufferGeometry) {
        stats.vertices += obj.geometry.attributes.position.count;

        if (obj instanceof Mesh) {
          if (obj.geometry.index) {
            stats.triangles += obj.geometry.index.count / 3;
          } else {
            stats.triangles += obj.geometry.attributes.position.count / 3;
          }
        } else if (obj instanceof LineSegments) {
          if (obj.geometry.index) {
            stats.triangles += obj.geometry.index.count / 2;
          } else {
            stats.triangles += obj.geometry.attributes.position.count / 2;
          }
        } else if (obj instanceof Line) {
          stats.triangles += obj.geometry.attributes.position.count - 1;
        }
      }

      if (obj.material instanceof Material) {
        if (checked_materials[obj.material.uuid] !== true) {
          checked_materials[obj.material.uuid] = true;
          stats.materials++;
          const map = (obj.material as any).map as Texture;

          if (map != null) {
            if (checked_textures[map.uuid] !== true) {
              checked_textures[map.uuid] = true;
              stats.textures++;
              if (map.image instanceof Image) {
                if (
                  map.image.naturalHeight >= 4096 ||
                  map.image.naturalWidth >= 4096
                ) {
                  stats.textures4k++;
                }
              } else if (map.image instanceof ImageBitmap) {
                if (map.image.height >= 4096 || map.image.width >= 4096) {
                  stats.textures4k++;
                }
              }
            }
          }
        }
      }
    }
  });

  return stats;
}

const styles = {
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: 1.5,
  },
} as const;

function HealthLabel({
  value,
  recommendedMax,
  recommendedMin,
  max,
  min,
  label,
  info,
  truncate,
}: {
  value: number;
  recommendedMax?: number;
  recommendedMin?: number;
  min?: number;
  max?: number;
  label: string;
  info: string;
  truncate?: boolean;
}) {
  let isWarning = false;

  let color = "rgba(0,0,0,0.5)";

  if ((max && value > max) || (min && value < min)) {
    color = "rgba(217,30,24,1)";
    isWarning = true;
  } else if (
    (recommendedMax && value > recommendedMax) ||
    (recommendedMin && value < recommendedMin)
  ) {
    color = "rgba(247,202,24,1)";
    isWarning = true;
  }

  return (
    <Tooltip title={info}>
      <Button>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 80,
            alignItems: "center",
            textTransform: "none",
          }}
        >
          <Typography variant="caption" align="center" style={{ color: color }}>
            <strong>
              {truncate ? value.toFixed(2) : value.toLocaleString()}
            </strong>
            <br />
            {label}
          </Typography>
          {isWarning ? (
            <Warning style={{ color: isWarning ? color : "#9E9E9E" }} />
          ) : (
            <Done style={{ color: isWarning ? color : "#9E9E9E" }} />
          )}
        </div>
      </Button>
    </Tooltip>
  );
}
