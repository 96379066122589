import { LinearProgress, Link, Typography, Box } from "@mui/material";

/**
 * Visible while an upload is in progress
 */
export default function UploadProgress({
  progress,
  onCancel,
}: {
  progress: number;
  onCancel: () => any;
}) {
  return (
    <Box component="div" sx={styles.container}>
      {progress < 1 && (
        <>
          <Typography variant="caption" sx={styles.text}>
            {(progress * 100).toFixed(2)}%...
            <Link
              variant="caption"
              style={{ cursor: "pointer" }}
              onClick={onCancel}
            >
              Cancel
            </Link>
          </Typography>
          <LinearProgress variant="determinate" value={progress * 100} />
        </>
      )}
      {progress >= 1 && (
        <>
          <Typography variant="caption" sx={styles.text}>
            Finalizing...
          </Typography>
          <LinearProgress variant="indeterminate" />
        </>
      )}
    </Box>
  );
}

const styles = {
  container: {
    minWidth: 120,
    textAlign: "right",
    marginBottom: 0.5,
    flexDirection: "column",
    display: "flex",
  },
  text: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    textAlign: "left",
  },
} as const;
