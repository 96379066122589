import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  FormHelperText,
  Stack,
} from "@mui/material";
import WorkerButton from "./components/WorkerButton";
import { useMediaQuery } from "@mui/material";
import { useAuth } from "Auth";
import { useEffect } from "react";
import { useAlerts } from "Alerts";
import firebase from "firebase/compat/app";

const emailRegex = /^[^@\s]+@[^@\s.]+.[^@\s]+$/g;

const SUPPORT_TICKET_FUNCTION = "appSupportTicket";

interface SupportTicketRequest {
  // Received in POST request
  from: {
    name: string;
    email: string;
  };
  subject: string;
  body: string;
}

export function HelpDialog({
  open,
  onClose,
  prompt,
}: {
  open: boolean;
  onClose: () => any;
  prompt?: string;
}) {
  const fullScreen = useMediaQuery("(max-width:450px)");
  const alerts = useAlerts();
  const auth = useAuth();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [working, setWorking] = useState<boolean>();
  const [errorMsg, setErrorMsg] = useState<string>();

  useEffect(() => {
    setErrorMsg(undefined);
  }, [name, email, subject, message]);

  async function submit() {
    setWorking(true);
    try {
      // Validate inputs
      if (!auth?.displayName) {
        if (!/^.{3,100}$/g.test(name)) {
          setErrorMsg("Please enter your name");
          return;
        }
      }

      if (!auth?.email) {
        if (!emailRegex.test(email)) {
          setErrorMsg("Please a valid email address");
          return;
        }
      }

      if (!/^.{3,100}$/g.test(subject)) {
        setErrorMsg("Please enter a subject.");
        return;
      }

      if (!/^.{5,1000}$/g.test(message)) {
        setErrorMsg("Please enter a message.");
        return;
      }

      const request: SupportTicketRequest = {
        from: {
          name: auth?.displayName ?? name,
          email: auth?.email ?? email,
        },
        subject: subject,
        body: message,
      };

      await firebase.functions().httpsCallable(SUPPORT_TICKET_FUNCTION)(
        request
      );

      setMessage("");
      setSubject("");
      onClose();
      await alerts.alert(
        "Thanks for your enquiry. We'll get back to you shortly.",
        "Success"
      );
    } catch (err) {
      console.error("Error submitting ticket");
      await alerts.alert(
        "Something went wrong submitting your ticket. Please try again or email support@twinbuild.com if issues persist.",
        "Error"
      );
    } finally {
      setWorking(false);
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      PaperProps={{
        sx: { width: { sm: 450 } },
      }}
      onClose={onClose}
    >
      <DialogTitle>Contact Support</DialogTitle>
      <DialogContent>
        <Stack>
          <Typography variant="body2" gutterBottom>
            {prompt ?? "Please enter your message  and we'll get back to you."}
          </Typography>
          {!auth && (
            <>
              <TextField
                sx={styles.field}
                value={name}
                onChange={(ev) => setName(ev.target.value)}
                size="small"
                label="Name"
                variant="outlined"
                autoFocus={auth == null}
              />
              <TextField
                sx={styles.field}
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
                size="small"
                label="Email"
                variant="outlined"
              />
            </>
          )}
          <TextField
            sx={styles.field}
            value={subject}
            onChange={(ev) => setSubject(ev.target.value)}
            size="small"
            label="Subject"
            variant="outlined"
            autoFocus={auth != null}
          />
          <TextField
            sx={styles.field}
            value={message}
            onChange={(ev) => setMessage(ev.target.value)}
            multiline
            rows={4}
            maxRows={8}
            size="small"
            label="Message"
            variant="outlined"
          />
          <FormHelperText error>{errorMsg}</FormHelperText>

          {auth && (
            <Typography variant="caption">
              We'll respond via email to {auth.email}.
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <WorkerButton working={working} onClick={submit} color="primary">
          Submit
        </WorkerButton>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  field: {
    marginTop: 1,
  },
} as const;

export default HelpDialog;
