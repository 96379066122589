import {
  Button,
  Fade,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Tooltip,
  useMediaQuery,
  Theme,
  Box,
} from "@mui/material";
import { useState } from "react";
import { Info, ViewInAr } from "@mui/icons-material";
import { Model } from "Types";
import firebase from "firebase/compat/app";
import config, { isHoloLens } from "config";
import { Skeleton } from "@mui/material";
import EditModelInfo from "./ModelsItemEditorView";
import { useAlerts } from "Alerts";
import { useNavigate } from "react-router-dom";
import QRModelCodeView, { fetchModelToken } from "View/QRModelCodeView";
import { useModelThumbnailUri } from "hooks/useModelUri";

export function ListModelsItem({
  model,
}: {
  model: firebase.firestore.QueryDocumentSnapshot<Model>;
}) {
  const data = model.data() as Model;
  const { alert } = useAlerts();

  const sml = useMediaQuery("(max-width:600px)");
  const [loaded, setLoaded] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);

  const [qrVisible, setQrVisible] = useState<boolean>(false);
  const [working, setWorking] = useState<boolean>(false);

  const navigate = useNavigate();

  const weblink = `/models/${model.id}`;

  async function openOnHololens() {
    setWorking(true);
    try {
      const response = await fetchModelToken({
        model_id: model.id,
      });
      const deeplink = `${config.protocol}://m/${model.id}?t=${response.token}`;
      window.location.href = deeplink;
    } catch (err) {
      console.error("Error fetching model token, err");
      await alert("Authorization error. Please try again.");
    } finally {
      setWorking(false);
    }
  }

  const thumbUri = useModelThumbnailUri(data);

  return (
    <>
      <EditModelInfo
        open={editing}
        model={model}
        onClose={() => setEditing(false)}
      />
      <QRModelCodeView
        open={qrVisible}
        onClose={() => setQrVisible(false)}
        model={data}
      />
      <ImageListItem
        cols={1}
        onClick={() => {
          if (isHoloLens) {
            if (!working) {
              openOnHololens();
            }
          } else {
            navigate(weblink);
          }
        }}
        sx={sml ? styles.tile1 : styles.tile3}
      >
        <Fade in={loaded} appear timeout={1000}>
          <Box component="div" sx={styles.imageContainer}>
            <img
              src={thumbUri}
              alt={"model"}
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              onLoad={() => setLoaded(true)}
            />
          </Box>
        </Fade>
        <Box component="div" sx={{ ...styles.image, ...styles.imageHover }} />

        <Fade in appear timeout={2000}>
          <ImageListItemBar
            title={<strong>{data.model_name ?? "Unnamed Model"}</strong>}
            subtitle={
              <>
                <strong>{data.user_name ?? data.user_email}</strong>
                <br />
                {(data.created_at as any).toDate().toLocaleString()}
              </>
            }
            sx={styles.tilebar}
            actionIcon={
              <Box component="div" sx={styles.actionIcons}>
                {!isHoloLens && (
                  <Tooltip title="View in AR">
                    <Button
                      sx={styles.actionIcon}
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setQrVisible(true);
                      }}
                    >
                      <ViewInAr />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Model Info">
                  <IconButton
                    sx={styles.actionIcon}
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      setEditing(true);
                    }}
                    size="large"
                  >
                    <Info />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          />
        </Fade>
      </ImageListItem>
    </>
  );
}

export function ListModelsItemSkeleton() {
  const sml = useMediaQuery("(max-width:600px)");

  return (
    <ImageListItem sx={sml ? styles.tile1 : styles.tile3}>
      <Box component="div" sx={styles.skeleton}>
        <Box component="div" sx={styles.skeletonContent}>
          <Skeleton
            id="date"
            variant="rectangular"
            width={"30%"}
            height={8}
            style={{
              left: 16,
              bottom: 16,
              position: "absolute",
            }}
          />
          <Skeleton
            id="title"
            variant="rectangular"
            width={"50%"}
            height={16}
            style={{
              left: 16,
              bottom: 28,
              position: "absolute",
            }}
          />

          <Skeleton
            id="button1"
            variant="circular"
            width={32}
            height={32}
            style={{
              right: 4,
              bottom: 16,
              position: "absolute",
            }}
          />

          <Skeleton
            id="button2"
            variant="circular"
            width={32}
            height={32}
            style={{
              right: 52,
              bottom: 16,
              position: "absolute",
            }}
          />

          <Skeleton
            id="image"
            variant="rectangular"
            width={"100%"}
            height={"100%"}
            style={{
              bottom: 64,
              position: "absolute",
            }}
          />
        </Box>
      </Box>
    </ImageListItem>
  );
}

const styles = {
  tile3: {
    cursor: "pointer",
    padding: "2px",
  },
  tile1: {
    cursor: "pointer",
    padding: "2px",
  },
  imageContainer: {
    paddingTop: "100%",
    width: "100%",
    backgroundColor: "#e8e8e8",
  },
  image: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  imageHover: {
    transition: "opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: (theme: Theme) => {
      return `${theme.palette.primary.main}15`;
    },
    borderStyle: "solid",
    border: 1,
    borderColor: "primary.main",
    boxSizing: "border-box",
    //boxShadow: `inset 0 0 100px ${theme.palette.primary.main}`,
    opacity: 0.1,
    "&:hover": {
      opacity: 0.5,
    },
  },
  actionIcon: {
    color: "#fff",
  },
  actionIcons: {
    display: "flex",
    flexDirection: "row",
    pointerEvents: "all",
  },
  skeleton: {
    paddingTop: "100%",
    cursor: "default",
  },
  skeletonContent: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  tilebar: {
    pointerEvents: "none",
  },
} as const;
