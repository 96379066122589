import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Box,
  SelectChangeEvent,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Typography,
} from "@mui/material";
import { LoaderTypes, ValidMimeType } from "./FileFormats";
import { usePreference } from "hooks/usePreference";
import { useMemo } from "react";

export type UnitType =
  | "meters"
  | "centimeters"
  | "millimeters"
  | "feet"
  | "inches";

export const UnitValues: Record<UnitType, number> = {
  meters: 1,
  centimeters: 0.01,
  millimeters: 0.001,
  feet: 0.3048,
  inches: 0.0254,
};

export type ModelOptions = {
  scale?: number;
  yUp?: boolean;
  size?: number;
};

export default function ModelOptionsDialog({
  open,
  onClose,
  type,
}: {
  open: boolean;
  onClose: (options: ModelOptions) => any;
  type?: ValidMimeType;
}) {
  const { value: units, setValue: setUnits } = usePreference(
    "preferred_unit_system_scale",
    "meters"
  );
  const { value: yUp, setValue: setYUp } = usePreference(
    "preferred_yUp",
    "true"
  );

  const handleUnitChange = (event: SelectChangeEvent) => {
    setUnits(event.target.value as UnitType);
  };

  // Note storage is in GLTF (Y Up RHS) coordinates whereas we present the user with CAD (Z-Up LHS) coordinates
  const fullScreen = useMediaQuery("(max-width:600px)");

  const options: ModelOptions = useMemo(() => {
    return {
      scale: UnitValues[units as UnitType],
      yUp: yUp === "true",
    };
  }, [units, yUp]);

  return (
    <>
      <Dialog
        open={open}
        disableEscapeKeyDown={true}
        keepMounted={false}
        fullScreen={fullScreen}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          {type ? LoaderTypes[type].toUpperCase() : "Model"} Import Options
        </DialogTitle>
        <DialogContent>
          <Box component="div" sx={{ mt: 1, mb: 2 }}>
            <Typography variant="caption" gutterBottom>
              This {type ? LoaderTypes[type].toUpperCase() : ""} model was not
              created with a Twinbuild CAD plugin and does not specify a
              coordinate system or units. Please select the units of the file
              and check the box if Y is the up-axis.
            </Typography>
          </Box>
          <Box component="div" sx={styles.labelContainer}>
            <FormControl fullWidth size="small" sx={{ minWidth: 160 }}>
              <InputLabel id="select-units-label">File Units</InputLabel>
              <Select
                labelId="select-units-label"
                id="select-units"
                value={units}
                label="File Units"
                onChange={handleUnitChange}
              >
                {Object.keys(UnitValues).map((units, i) => {
                  return (
                    <MenuItem key={i} value={units}>
                      {units}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={yUp === "true"}
                  onChange={(e) => setYUp(e.target.checked ? "true" : "false")}
                  inputProps={{ "aria-label": "select-orientation" }}
                />
              }
              label="Y is Up"
              labelPlacement="start"
              sx={{ ...styles.checkBox }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(options)} sx={styles.buttonPositive}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const styles = {
  labelContainer: {
    display: "flex",
    mt: 1,
  },
  buttonPositive: {
    color: "primary.main",
  },
  checkBox: {
    marginLeft: "8px",
    minWidth: 128,
  },
} as const;
