import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./Auth.css";
import firebase from "firebase/compat/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  Typography,
  useTheme,
} from "@mui/material";
import * as firebaseui from "firebaseui";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import { Link, useLocation } from "react-router-dom";
import config from "./config";
import { CustomClaims, TeamScope, TeamScopes } from "Types";
import { ReactComponent as Logo } from "static/images/wordmark.svg";
import { Alerts } from "./Alerts";

firebase.initializeApp(config.firebase);
const db = firebase.firestore();

if (window.location.hostname === "localhost") {
  firebase.functions().useEmulator("localhost", 5001);
  console.info("Using functions emulator");
}

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

type UserContextType = firebase.User & {
  checkForEmailVertification: () => Promise<boolean>;
};

export const UserContext = createContext<UserContextType | null | undefined>(
  undefined
);

export const ClaimsContext = createContext<
  CustomClaimsContextType | null | undefined
>(undefined);

type CustomClaimsContextType = CustomClaims & {
  isOwner: boolean;
  isAdminOrAbove: boolean;
  isFinanceManagerOrAbove: boolean;
  isUsersManagerOrAbove: boolean;
  isFinanceManager: boolean;
  isModelsManagerOrAbove: boolean;
  isInATeam: boolean;
};

const auth = firebase.auth();
const analytics = firebase.analytics();

export const logEvent = (
  eventName: string,
  params?: { [key: string]: any }
) => {
  analytics.logEvent(eventName, params);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined);
  const [claims, setClaims] = useState<CustomClaims | null | undefined>(
    undefined
  );

  useEffect(() => {
    if (user) {
      firebase.analytics().setUserId(user.uid);
    }
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      console.info("User", user);
      setUser(user);
      setClaims(undefined);
    });
  }, []);

  const checkForEmailVertification = useCallback(async () => {
    if (auth.currentUser) {
      await auth.currentUser.reload();
      return auth.currentUser.emailVerified;
    }
    return false;
  }, []);

  useEffect(() => {
    if (user) {
      return db.doc(`claims/${user.uid}`).onSnapshot(async (doc) => {
        if (!doc.exists) {
          setClaims(null);
          return;
        }

        const id = await user.getIdTokenResult(true);
        setClaims(id.claims as CustomClaims);
        console.info("Claims", id.claims);
      });
    } else if (user === null) {
      setClaims(null);
    } else {
      setClaims(undefined);
    }
  }, [user]);

  const userTyped = useMemo(() => {
    if (user) {
      // Add additional functions to the user object
      const typed: UserContextType = user as any;
      typed.checkForEmailVertification = checkForEmailVertification;
      return typed;
    }
    return user;
  }, [user, checkForEmailVertification]);

  const claimsTyped = useMemo(() => {
    if (!claims) return claims;

    const scopes: TeamScope[] = claims.team_scopes ?? [];
    const isOwner = scopes.includes(TeamScopes.owner);
    const isFinanceManager = scopes.includes(TeamScopes.finances);
    const isAdminOrAbove = isOwner || scopes.includes(TeamScopes.admin);
    const isFinanceManagerOrAbove =
      isAdminOrAbove || scopes.includes(TeamScopes.finances);
    const isUsersManagerOrAbove =
      isAdminOrAbove || scopes.includes(TeamScopes.manager);
    const isModelsManagerOrAbove =
      isUsersManagerOrAbove || scopes.includes(TeamScopes.user);

    const typed: CustomClaimsContextType = {
      team_scopes: claims.team_scopes,
      team_id: claims.team_id,
      isInATeam: claims.team_id != null,
      admin: claims.admin,
      isOwner,
      isAdminOrAbove,
      isFinanceManagerOrAbove,
      isUsersManagerOrAbove,
      isModelsManagerOrAbove,
      isFinanceManager,
    };
    return typed;
  }, [claims]);

  return (
    <UserContext.Provider value={userTyped}>
      <ClaimsContext.Provider value={claimsTyped}>
        {children}
      </ClaimsContext.Provider>
    </UserContext.Provider>
  );
};

/**
 * @returns A reference to the current user, or null.
 */
export const useAuth = () => useContext(UserContext);

/**
 * @returns A reference to the current user claims, or null.
 */
export const useClaims = () => useContext(ClaimsContext);

export const AuthDialog = ({
  open,
  onCancel,
  onSuccess,
  children,
}: {
  open: boolean;
  onCancel?: () => any;
  onSuccess?: () => any;
  children?: ReactNode;
}) => {
  const theme = useTheme();

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          prompt: "select_account",
        },
      },
      {
        provider: "microsoft.com",
        customParameters: {
          prompt: "select_account",
        },
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        buttonColor: theme.palette.primary.main,
        signInMethod:
          firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {
        if (onSuccess) onSuccess();
        return false;
      },
    },
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  };

  const uiCallback = (ui: firebaseui.auth.AuthUI) => {
    ui.disableAutoSignIn();
  };

  const fullscreen = useMediaQuery("(max-width:600px),(max-height:600px)");

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          minWidth: fullscreen ? "unset" : 350,
          minHeight: fullscreen ? "unset" : 280,
          maxWidth: fullscreen ? "unset" : 250,
        },
      }}
      fullScreen={fullscreen}
      onClose={() => {
        if (onCancel) onCancel();
      }}
    >
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          //justifyContent: "center",
          alignItems: "center",
          paddingBottom: 0,
        }}
      >
        <Logo
          style={{
            fill: theme.palette.primary.main,
            width: 64,
            height: 64,
            marginBottom: 16,
          }}
        />
        <span
          id="custom-content"
          style={{
            width: 220,
            marginTop: 8,
            marginBottom: 16,
            fontSize: 14,
          }}
        >
          {children}
        </span>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          uiCallback={uiCallback}
          firebaseAuth={auth}
        />
      </DialogContent>
      <DialogActions>
        {onCancel && <Button onClick={onCancel}>Close</Button>}
      </DialogActions>
    </Dialog>
  );
};

export function EmailVerificationDialog({
  open,
  message,
}: {
  open: boolean;
  message?: string;
}) {
  const user = useAuth();
  const emailVerified = user?.emailVerified;
  const fullscreen = useMediaQuery("(max-width:600px),(max-height:600px)");
  // TODO refresh every X seconds to redirect?

  return (
    <Dialog open={open} fullScreen={fullscreen}>
      <DialogTitle>Email Verification</DialogTitle>
      {emailVerified === false && <EmailNotVerifiedContent message={message} />}
      {emailVerified && <EmailVerifiedContent />}
    </Dialog>
  );
}

function EmailVerifiedContent() {
  return (
    <>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Your email address is verified.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button component={Link} to="/">
          Go Home
        </Button>
      </DialogActions>
    </>
  );
}

function EmailNotVerifiedContent({ message }: { message?: string }) {
  const user = useAuth()!;
  const [resent, setResent] = useState<boolean>(false);

  const verified = user?.emailVerified ?? false;

  const sendVerificationEmail = useCallback(
    async (manual: boolean) => {
      if (user) {
        await user.sendEmailVerification();
        if (manual) {
          setResent(true);
        }
        console.info("Verification email sent");
      }
    },
    [user]
  );

  useEffect(() => {
    // Refresh ID token every few seconds
    // So we can detect when an email verification goes through
    if (user && verified === false) {
      sendVerificationEmail(false);

      const iv = setInterval(async () => {
        if (await user.checkForEmailVertification()) {
          window.location.reload();
        }
      }, 5000);

      return () => {
        clearInterval(iv);
      };
    }
  }, [user, verified, sendVerificationEmail]);

  return (
    <>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {message ?? `Please verify your email address to continue`}
          <br />
          <br />
          We've emailed a link to {user?.email}.
        </Typography>
        <Typography variant="caption">
          Having trouble? Check your spam folder.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={resent}
          onClick={() => {
            sendVerificationEmail(true);
            setResent(true);
          }}
        >
          Resend Email
        </Button>
      </DialogActions>
    </>
  );
}

export const RouteAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    if (location) {
      console.info("Location", window.location.href);
      analytics.setCurrentScreen(
        location.pathname + location.search + location.hash
      );
      analytics.logEvent("page_view", {
        page_location: location.pathname + location.search + location.hash,
      });
    }
  }, [location]);
  return null;
};

/**
 * Ensures a user has recently logged in, and if they haven't prompt them for a refresh
 * @param duration The duration in millis for how recently they must have signed in
 */
export async function ensureRecentAuth(
  /**
   * The duration in millis for how recently they must have signed in
   */
  duration: number,
  /**
   * Alerts context for communicating with the user
   */
  alerts: Alerts
) {
  const user = auth.currentUser;
  if (!user?.email) return null;
  const token = await user.getIdTokenResult(false);
  if (!token.signInProvider) return false;

  try {
    if (Date.now() - Date.parse(token.authTime) > duration) {
      console.info("Reauthentication required");
      switch (token.signInProvider) {
        case firebase.auth.EmailAuthProvider.PROVIDER_ID:
          // Prompt for password
          const pw = await alerts.prompt(
            "Please re-enter your password",
            undefined,
            "Authentication Required",
            { password: true }
          );
          if (!pw) return null;
          await user.reauthenticateWithCredential(
            firebase.auth.EmailAuthProvider.credential(user.email, pw)
          );
          await user.getIdToken(true);
          return true;
        default:
          await alerts.alert(
            "Please re-enter your credentials to proceed",
            "Reauthentication Required"
          );
          const provider = new firebase.auth.OAuthProvider(
            token.signInProvider
          );
          provider.setCustomParameters({
            prompt: "consent",
            login_hint: user.email,
          });
          await user.reauthenticateWithPopup(provider);
          await user.getIdToken(true);
          return true;
      }
    } else {
      return true;
    }
  } catch (err) {
    await alerts.alert(err.message ?? "Unable to authenticate.");
    return null;
  }
}
