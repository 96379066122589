import React, { Suspense, useCallback } from "react";
import LoadScreen from "./LoadScreen";
import { LoaderFileFormat } from "./FileFormats";
import { ConvertedFile } from "three/useFileConverter";
import { ModelOptions } from "./ModelOptionsDialog";

const ModelParser = React.lazy(() => import("../three/ModelParser"));
/**
 * The load screen for a ModelView.
 * Including lazy loading
 */
export default function ModelViewLoader({
  uri,
  modelOptions,
  onPreviewConverted,
  format,
  modelId,
  uiDomElement,
  previewSize,
}: {
  uri: string;
  modelOptions?: ModelOptions;
  onPreviewConverted?: (data: ConvertedFile) => any;
  format: LoaderFileFormat;
  modelId?: string;
  uiDomElement: HTMLDivElement | null;
  previewSize?:number
}) {
  const [progress, setProgress] = React.useState<number>(0);

  const onProgress = useCallback(
    ({ loaded, total }: { loaded: number; total: number }) =>
      setProgress((loaded / total) * 100),
    []
  );

  return (
    <Suspense fallback={<LoadScreen progress={progress} />}>
      <ModelParser
        uri={uri}
        modelId={modelId}
        onProgress={onProgress}
        onPreviewConverted={onPreviewConverted}
        modelOptions={modelOptions}
        loader={format}
        uiDomElement={uiDomElement}
        previewSize={previewSize}
      />
    </Suspense>
  );
}
