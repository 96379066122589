import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Model } from "Types";
import { useAlerts } from "Alerts";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useModelThumbnailUri } from "../hooks/useModelUri";

export default function EditModelInfo({
  open,
  model,
  onClose,
}: {
  open: boolean;
  model: firebase.firestore.QueryDocumentSnapshot<Model>;
  onClose: () => any;
}) {
  const data = model.data() as Model;
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);
  const [name, setName] = useState<string | null>(data.model_name);
  const [dataValid, setDataValid] = useState<boolean>(true);
  const [working, setWorking] = useState<boolean>(false);
  const { confirm } = useAlerts();

  const thumbUri = useModelThumbnailUri(data);

  async function confirmDelete() {
    if (
      await confirm(
        "Are you sure you want to delete this model? This can not be undone."
      )
    ) {
      await model.ref.delete();
    }
  }

  async function confirmChanges() {
    if (!dataValid) return;
    setWorking(true);

    const update: Partial<Model> = {
      model_name: name,
    };

    try {
      await model.ref.update(update);
      setWorking(false);
      onClose();
    } catch {
      setWorking(false);
    }
  }

  useEffect(() => {
    setDataValid(name != null && name !== "" && name.length < 60);
  }, [name]);

  const fullScreen = useMediaQuery("(max-width:600px)");
  const theme = useTheme();

  return (
    <Dialog open={open} fullScreen={fullScreen} onClose={onClose}>
      <DialogTitle>Model Information</DialogTitle>
      <DialogContent>
        <FormGroup>
          <Fade in={imgLoaded} appear timeout={1000}>
            <img
              style={{
                maxWidth: "calc(100vw - 48px)",
                maxHeight: "calc(100vw - 48px)",
                borderRadius: 1,
                border: "1px solid",
                borderColor: theme.palette.grey[400],
                marginBottom: 1,
                width: fullScreen ? "auto" : 300,
                height: fullScreen ? "auto" : 300,
              }}
              alt="Model Thumbnail"
              src={thumbUri}
              onLoad={() => setImgLoaded(true)}
            />
          </Fade>
          <TextField
            label="Title"
            variant="outlined"
            size="small"
            value={name ?? ""}
            onChange={(ev) => setName(ev.target.value)}
            disabled={working}
            sx={styles.textField}
          />
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Author</TableCell>
                  <TableCell>{data.user_name}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{data.user_email}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell>
                    {(data.created_at as any).toDate().toLocaleString()}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>{model.id}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Markers</TableCell>
                  <TableCell>{data.marker_count ?? 0}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          sx={styles.buttonNegative}
          onClick={confirmDelete}
          disabled={working}
        >
          Delete
        </Button>
        <Button sx={styles.buttonNeutral} onClick={onClose} disabled={working}>
          Cancel
        </Button>
        <Button
          sx={styles.buttonPositive}
          disabled={!dataValid || working}
          onClick={confirmChanges}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  thumbnail: {
    maxWidth: "calc(100vw - 48px)",
    maxHeight: "calc(100vw - 48px)",
    borderRadius: 1,
    border: "1px solid",
    borderColor: "grey.400",
    marginBottom: 1,
  },
  thumbnailLimited: {
    width: 300,
    height: 300,
  },
  buttonPositive: {
    color: "primary.main",
  },
  buttonNeutral: {
    color: "grey.500",
  },
  buttonNegative: {
    color: "error.main",
  },
  textField: {
    marginBottom: 1,
    marginTop: 1,
  },
};
