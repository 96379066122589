import { logEvent, useAuth, useClaims } from "Auth";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { Model } from "Types";
import UploadProgress from "./UploadProgress";
import SignIn from "./SignIn";
import useCaptureContext from "hooks/useCaptureContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import useQueryString from "hooks/useQueryString";
import { ValidUploadMimeType } from "View/FileFormats";
import { useMarkers } from "Markers/useMarkers";

const fbStorage = firebase.storage();
const db = firebase.firestore();

/**
 * View and controller for uploading a model file.
 */
export default function Upload({
  uri,
  contentType,
  name,
  onComplete,
  disabled,
}: {
  uri: string;
  contentType: ValidUploadMimeType;
  name?: string;
  onComplete: (model: Model) => any;
  disabled: boolean;
}) {
  const claims = useClaims();
  const auth = useAuth();

  const { autoUpload } = useQueryString<{ autoUpload?: string }>();
  const [publishing, setPublishing] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [joinTeamDialogVisible, setJoinTeamDialogVisible] =
    useState<boolean>(false);

  const teamId = claims?.team_id;

  const canUploadModel = claims?.isInATeam && claims.isModelsManagerOrAbove;

  useEffect(() => {
    if (!complete && autoUpload != null && auth != null && !publishing) {
      setPublishing(true);
    }
  }, [autoUpload, publishing, complete, auth]);

  return (
    <div id="upload">
      {auth === null && (
        <SignIn
          message={"Sign in & upload"}
          onSuccess={() => setPublishing(true)}
        />
      )}
      {claims !== undefined && auth && !canUploadModel && (
        <>
          <JoinTeamDialog
            open={joinTeamDialogVisible}
            onClose={() => setJoinTeamDialogVisible(false)}
          />
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={() => setJoinTeamDialogVisible(true)}
            style={{
              width: "100%",
              minWidth: 150,
            }}
          >
            Upload
          </Button>
        </>
      )}
      {auth && teamId && canUploadModel && (
        <>
          {!publishing && (
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={() => setPublishing(true)}
              style={{
                width: "100%",
                minWidth: 150,
              }}
            >
              Upload
            </Button>
          )}

          {publishing && !complete && (
            <UploadManager
              localUri={uri}
              user={auth}
              fileName={name != null ? name.toString() : null}
              contentType={contentType}
              teamId={teamId}
              onComplete={(modelId, model) => {
                setComplete(true);
                onComplete(model);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

function JoinTeamDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => any;
}) {
  const fs = useMediaQuery("(max-width:400px)");
  return (
    <Dialog fullScreen={fs} open={open} onClose={onClose}>
      <DialogTitle>Team Required</DialogTitle>
      <DialogContent>
        A team with a Twinbuild subscription is required to upload models.
        Contact your admin to request an invitation to an existing team, or
        create a new team with a free 14 day trial from your account dashboard.
        Would you like to do this now?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={() => {
            window.location.href = "https://account.twinbuild.com";
          }}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Logic component responsible for uploading a file, handling errors, etc.
 */
function UploadManager({
  localUri,
  user,
  fileName,
  contentType,
  onComplete,
  teamId,
}: {
  localUri: string;
  user: firebase.User;
  fileName: string | null;
  contentType: string;
  onComplete: (modelId: string, model: Model) => any;
  teamId: string;
}) {
  // The current upload progress
  const [progress, setProgress] = useState<number>(0);
  // True if the upload was cancelled by the user
  const [cancelled, setCancelled] = useState<boolean>(false);

  const viewContext = useCaptureContext();
  const captureThumbnail = viewContext?.captureThumbnail;
  const { commitToModel } = useMarkers();

  useEffect(() => {
    if (cancelled) return;
    if (!captureThumbnail) {
      return;
    }

    let cancel = () => {};
    let interrupted = false;

    (async () => {
      // Refresh auth
      await user.getIdToken(true);

      let blob: Blob;
      try {
        blob = await (await fetch(localUri)).blob();
      } catch (err) {
        await alert("Unable to read file");
        setCancelled(true);
        console.error(err);
        return;
      }

      console.info("Capturing thumbnail");
      let image: string;
      try {
        image = await captureThumbnail({
          size: 512,
          format: "image/jpeg",
        });
        console.info("Captured thumbnail @ 512");
      } catch (err) {
        await alert("Unable to capture thumbnail");
        console.error("Unable to capture thumbnail", err);
        setCancelled(true);
        return;
      }

      if (!["model/gltf+json", "model/gltf-binary"].includes(contentType)) {
        await alert("Invalid file type");
        console.error("Invalid file type", contentType);
        setCancelled(true);
        return;
      }

      const fileType = (() => {
        if (contentType === "model/gltf+json") return ".gltf";
        if (contentType === "model/gltf-binary") return ".glb";
      })();

      // Create a document reference to get an Id
      const doc = db.collection("models").doc();

      // Create a storage reference to where we will be uploading
      const storageRef = fbStorage.ref(
        `teams/${teamId}/models/${doc.id}/${doc.id}${fileType}`
      );

      console.info("Starting upload", storageRef.fullPath);

      // Start the upload
      const uploadTask = storageRef.put(blob, {
        contentType: contentType,
        cacheControl: `max-age=${60 * 60 * 24 * 365}`,
      });

      // Report progress
      uploadTask.on("state_changed", (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 0.99);
      });

      console.info("Uploading thumbnail");
      // Create a storage reference to where we will be uploading
      const thumbRef = fbStorage.ref(
        `teams/${teamId}/models/${doc.id}/${doc.id}@512.jpg`
      );

      console.info("Starting thumbnail upload", thumbRef.fullPath);

      // Start the upload
      const thumbUploadTask = thumbRef.putString(image, "data_url", {
        contentType: "image/jpeg",
        cacheControl: `max-age=${60 * 60 * 24 * 365}`,
      });

      // Report progress from thumb upload
      thumbUploadTask.on("state_changed", (snapshot) => {
        //setProgress(
        //  (snapshot.bytesTransferred / snapshot.totalBytes) * 0.05 + 0.925
        //);
      });

      // Overwrite the cancel hook so that we are cancelling the upload task
      cancel = () => {
        uploadTask.cancel();
        thumbUploadTask.cancel();
      };

      // Wait for the storage upload
      try {
        await uploadTask;
        console.info("Upload complete.");
        await thumbUploadTask;
        console.info("Thumbnail upload complete");
      } catch (err) {
        console.error("Upload error", err);
        if (interrupted) return;
        await alert("Upload failed.");
        setCancelled(true);
        return;
      }

      // Wait for the thumbnail upload
      cancel = () => {};

      setProgress(1);

      const model: Model = {
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        model_id: doc.id,
        user_id: user.uid,
        user_name: user.displayName!,
        model_name: fileName,
        storage_path: storageRef.fullPath,
        content_type: contentType as "model/gltf+json" | "model/gltf-binary",
        thumbnail_storage_path: thumbRef.fullPath,
        size_bytes: blob.size,
        user_email: user.email!,
        team_id: teamId,
      };

      console.info("Creating database record", model);

      try {
        // Database write
        await doc.set(model);
        // Update our timestamp
        model.created_at = firebase.firestore.Timestamp.fromMillis(Date.now());
      } catch (err) {
        console.error("Error writing to database", err);
        deleteStorageRef(storageRef);
        deleteStorageRef(thumbRef);
        if (interrupted) return;
        await alert(`Unable to write to database (${err.code ?? "unknown"})`);
        setCancelled(true);
        return;
      }

      if (interrupted) return;

      console.info("Upload complete");
      logEvent("model_uploaded", { model_id: doc.id });

      await commitToModel(model);

      onComplete(doc.id, model);

      cancel = () => {};
    })();

    return () => {
      interrupted = true;
      cancel();
    };
  }, [
    localUri,
    contentType,
    fileName,
    user,
    cancelled,
    onComplete,
    captureThumbnail,
    commitToModel,
    teamId,
  ]);

  if (cancelled) return null;

  return (
    <UploadProgress progress={progress} onCancel={() => setCancelled(true)} />
  );
}

// We may need to delete the model if something else fails further downstream
async function deleteStorageRef(ref: firebase.storage.Reference) {
  console.log("Attempting to delete failed storage upload");
  try {
    await ref.delete();
  } catch (deleteErr) {
    console.error("Unable to delete storage file", deleteErr);
  }
}
