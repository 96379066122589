import {
  Button,
  ImageList,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import { AuthDialog, useAuth, useClaims } from "Auth";
import { useEffect, useMemo, useState } from "react";
import { Model } from "../Types";
import firebase from "firebase/compat/app";
import { ListModelsItem, ListModelsItemSkeleton } from "./ModelsViewItem";
import { AccountCollapse } from "./AccountCollapse";

const db = firebase.firestore();

export default function ModelsView() {
  const claims = useClaims();

  const auth = useAuth();

  const teamId = claims?.team_id;
  const canEditModels = claims?.isInATeam && claims.isModelsManagerOrAbove;

  const [models, setModels] = useState<
    firebase.firestore.QueryDocumentSnapshot<Model>[]
  >();

  const [authVisible, setAuthVisible] = useState<boolean>(false);

  useEffect(() => {
    if (teamId != null && canEditModels) {
      return db
        .collection("models")
        .where("team_id", "==", teamId)
        .onSnapshot((snapshot) => {
          setModels(
            snapshot.docs
              .map(
                (doc) => doc as firebase.firestore.QueryDocumentSnapshot<Model>
              )
              .sort((a, b) => {
                const ad = a.data().created_at as firebase.firestore.Timestamp;
                const bd = b.data().created_at as firebase.firestore.Timestamp;
                return bd.seconds - ad.seconds;
              })
          );
        });
    } else {
      setModels(undefined);
    }
  }, [teamId, canEditModels]);

  const sml = useMediaQuery("(max-width:600px)");

  const skeletons: JSX.Element[] | null = useMemo(() => {
    if (models !== undefined) return null;
    if (!canEditModels) return null;

    const components = [];
    for (let i = 0; i < 3; i++) {
      components.push(<ListModelsItemSkeleton key={i} />);
    }
    return components;
  }, [models, canEditModels]);

  const rendergrid = skeletons != null || (models?.length ?? 0) > 0;

  return (
    <AccountCollapse
      title="Team Models"
      description=" View, edit or publish models in your team."
      expand
    >
      <AuthDialog
        open={auth == null && authVisible}
        onCancel={() => setAuthVisible(false)}
      >
        Sign in to your Twinbuild account to access team models.
      </AuthDialog>
      <Box component="div" sx={styles.content}>
        {rendergrid && (
          <ImageList cols={sml ? 1 : 3} sx={{ width: "100%" }}>
            {skeletons}
            {models?.map((model) => (
              <ListModelsItem key={model.id} model={model} />
            ))}
          </ImageList>
        )}
        {auth === null && (
          <>
            <Button
              color="primary"
              variant="outlined"
              sx={styles.button}
              onClick={() => {
                setAuthVisible(true);
              }}
            >
              Sign in to view
            </Button>
          </>
        )}
        {!canEditModels && teamId != null && (
          <Typography variant="caption" sx={styles.empty}>
            You don't have permission to access team models.
          </Typography>
        )}
        {auth && claims !== undefined && teamId == null && (
          <>
            <Typography variant="caption" sx={styles.empty} gutterBottom>
              You must join a team to upload and view models.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              sx={styles.button}
              href="https://account.twinbuild.com"
            >
              Account Dashboard
            </Button>
          </>
        )}
        {canEditModels && (models?.length ?? -1) === 0 && (
          <Typography variant="caption" sx={styles.empty}>
            No models uploaded yet.
          </Typography>
        )}
      </Box>
    </AccountCollapse>
  );
}

const styles = {
  gridList: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    margin: 0,
  },
  empty: {
    color: "grey.500",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
  },
  button: {
    maxWidth: 200,
  },
} as const;
