const GltfMimeType = "model/gltf+json";
const GlbMimeType = "model/gltf-binary";
const IfcMimeType = "application/ifc";
const fbxMimeType = "application/fbx";
const dxfMimeType = "application/dxf";
const objMimeType = "model/obj";
const plyMimeType = "application/ply";
const stlMimeType = "application/stl";

export type LoaderFileFormat =
  | "gltf"
  | "ifc"
  | "fbx"
  | "obj"
  | "dxf"
  | "ply"
  | "stl";

export type ValidFileExtension =
  | "gltf"
  | "glb"
  | "ifc"
  | "fbx"
  | "obj"
  | "dxf"
  | "ply"
  | "stl";
export const ValidFileExtensions: ValidFileExtension[] = [
  "gltf",
  "glb",
  "ifc",
  "fbx",
  "obj",
  "dxf",
  "ply",
  "stl",
];

export type ValidMimeType =
  | typeof GltfMimeType
  | typeof GlbMimeType
  | typeof IfcMimeType
  | typeof objMimeType
  | typeof fbxMimeType
  | typeof dxfMimeType
  | typeof plyMimeType
  | typeof stlMimeType;

export type ValidUploadMimeType = typeof GltfMimeType | typeof GlbMimeType;

export const ValidMimeTypes: ValidMimeType[] = [
  GltfMimeType,
  GlbMimeType,
  IfcMimeType,
  fbxMimeType,
  objMimeType,
  dxfMimeType,
  plyMimeType,
  stlMimeType,
];

/**
 * Map of file extension => mime type
 */
export const MimeTypes: Record<ValidFileExtension, ValidMimeType> = {
  gltf: GltfMimeType,
  glb: GlbMimeType,
  ifc: IfcMimeType,
  fbx: fbxMimeType,
  obj: objMimeType,
  dxf: dxfMimeType,
  ply: plyMimeType,
  stl: stlMimeType,
};

/**
 * Map of mime type => which loader to use
 */
export const LoaderTypes: Record<ValidMimeType, LoaderFileFormat> = {
  "model/gltf+json": "gltf",
  "model/gltf-binary": "gltf",
  "application/ifc": "ifc",
  "application/fbx": "fbx",
  "model/obj": "obj",
  "application/dxf": "dxf",
  "application/ply": "ply",
  "application/stl": "stl",
};
