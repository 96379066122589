import { Button } from "@mui/material";
import { AccountHeader } from "./AccountCollapse";
import { Launch } from "@mui/icons-material";
import { useClaims } from "../Auth";

export function AccountLinkView() {
  const claims = useClaims();
  if (!claims?.isInATeam) return null;

  return (
    <>
      <AccountHeader
        title="My Account"
        description="View and manage account and team information."
        button={
          <Button
            color="primary"
            variant="outlined"
            href="https://account.twinbuild.com"
            target="_blank"
            endIcon={<Launch />}
          >
            Dashboard
          </Button>
        }
      />
    </>
  );
}

export default AccountLinkView;
