import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import queryString from "query-string";
import { Model, QRCode } from "../Types";
import { useEffect } from "react";
import firebase from "firebase/compat/app";
const db = firebase.firestore();

/**
 * A container for a preview OR marker reference
 * It's a preview if doc == undefined
 */
export type MarkerReference = {
  code: QRCode;
  doc?: firebase.firestore.DocumentReference;
  isNew?: boolean;
};

type MarkersContextData = {
  markers: MarkerReference[];
  /**
   * The model the markers have been committed to
   * If null, we are still in preview mode
   */
  model?: Model;
  commitToModel: (model: Model) => Promise<void>;
  addMarker: (code?: QRCode) => Promise<void>;
  deleteMarker: (marker: MarkerReference) => Promise<void>;
  updateMarker: (
    marker: MarkerReference,
    change: Partial<QRCode>
  ) => Promise<void>;
};

export const MarkersContext = createContext<MarkersContextData>({
  markers: [],
  commitToModel: () => null as any,
  addMarker: () => false as any,
  deleteMarker: () => null as any,
  updateMarker: () => null as any,
});

export const useMarkers = () => useContext(MarkersContext);

export function MarkersContextProvider({
  children,
  model,
}: {
  children: ReactNode;
  model?: Model;
}) {
  const [markers, setMarkers] = useState<MarkerReference[]>([]);

  // This ref is used so we can use the callbacks on the latest data
  // Without changing the callbacks or hitting state inconsistencies
  const refs = useRef<{
    markers: MarkerReference[];
    model: Model | undefined;
  }>({
    markers: [],
    model: model,
  });

  const [currentModel, setCurrentModel] = useState<Model | undefined>(model);

  useEffect(() => {
    if (!model) {
      setMarkers(
        updateMarkersRef(extractQRCodesFromSearch().map((code) => ({ code })))
      );
    }
  }, [model]);

  function updateMarkersRef(markers: MarkerReference[]) {
    refs.current.markers = markers;
    return markers;
  }

  useEffect(() => {
    refs.current.model = currentModel;
    if (currentModel) {
      // firestore fetch
      return db
        .collection("qrcodes")
        .where("team_id", "==", currentModel.team_id)
        .where("model_id", "==", currentModel.model_id)
        .onSnapshot((snapshot) => {
          // Set the isNew flag if we manually added a code
          const newCodeDocIds = snapshot
            .docChanges()
            .filter(
              (change) =>
                change.doc.metadata.hasPendingWrites &&
                change.type === "added" &&
                (change.doc.data() as QRCode).source === "manual"
            )
            .map((change) => change.doc.id);

          if (newCodeDocIds.length > 1) {
            console.warn("Detected multiple new QRcodes at once");
          }

          const newCodeExistingIds = refs.current.markers
            .filter((x) => x.isNew === true)
            .map((x) => x.code.code_id);

          setMarkers(
            updateMarkersRef(
              snapshot.docs.map((doc) => {
                const code: QRCode = {
                  ...(doc.data() as QRCode),
                  code_id: doc.id,
                };
                const ref: MarkerReference = {
                  code: code,
                  doc: doc.ref,
                  isNew:
                    newCodeDocIds.includes(doc.id) ||
                    newCodeExistingIds.includes(doc.id),
                };
                return ref;
              })
            )
          );
        });
    }
  }, [refs, currentModel]);

  const addMarker = useCallback(
    async (code?: QRCode): Promise<void> => {
      if (!code) {
        const label = Date.now()
          .toString(16)
          .split("")
          .reverse()
          .join("")
          .slice(0, 4);
        code = {
          position: [0, 0, 0],
          data: label,
          source: "manual",
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          model_id: model?.model_id ?? (null as any),
          team_id: model?.team_id ?? (null as any),
          code_id: db.collection("id").doc().id,
        };
      }

      if (refs.current.markers.find((x) => x.code.data === code!.data)) {
        throw new Error(`Can't add marker "${code.data}", ID already in use.`);
      }

      if (refs.current.model) {
        // Add the marker, the listener will pick it up and refresh
        code.model_id = refs.current.model.model_id;
        code.team_id = refs.current.model.team_id;
        const doc = db.collection("qrcodes").doc(code.code_id);
        await doc.set(code);
      } else {
        // Add the preview
        const marker: MarkerReference = {
          code,
          isNew: code.source === "manual",
        };
        setMarkers((p) => updateMarkersRef([...p, marker]));
      }
    },
    [refs, model]
  );
  const updateMarker = useCallback(
    async (marker: MarkerReference, update: Partial<QRCode>) => {
      if (marker.doc) {
        // Firestore connected
        await marker.doc.update(update);
      } else {
        // Local preview
        setMarkers((p) => {
          const ind = p.findIndex(
            (x) => x.code.code_id === marker.code.code_id
          );
          if (ind < 0) {
            console.warn("Marker not found in ref during update");
            return p;
          }
          p.splice(ind, 1, {
            ...marker,
            code: { ...marker.code, ...update },
          });
          return updateMarkersRef([...p]);
        });
      }
    },
    []
  );

  const deleteMarker = useCallback(async (marker: MarkerReference) => {
    // Delete doc
    if (marker.doc != null) {
      await marker.doc.delete();
    }
    // Delete preview
    else {
      setMarkers((p) => {
        const n = [...p];
        n.splice(p.indexOf(marker), 1);
        return updateMarkersRef(n);
      });
    }
  }, []);

  const commitToModel = useCallback(
    async (model: Model) => {
      console.info("Marker refs", refs.current.markers);
      const writes = refs.current.markers
        .filter((marker) => marker.doc == null)
        .map((marker) => {
          marker.code.model_id = model.model_id;
          marker.code.team_id = model.team_id;
          return marker.code;
        });

      if (writes.length === 0) {
        console.log("No markers to commit.");
      } else {
        console.log(`Committing ${writes.length} Markers`);

        const batch = db.batch();
        writes.forEach((write) => {
          batch.set(db.collection("qrcodes").doc(write.code_id), write);
        });
        await batch.commit();
      }
      setCurrentModel(model);
    },
    [refs]
  );

  return (
    <>
      <MarkersContext.Provider
        value={{
          model: currentModel,
          commitToModel,
          markers,
          addMarker,
          updateMarker,
          deleteMarker,
        }}
      >
        {children}
      </MarkersContext.Provider>
    </>
  );
}

function extractQRCodesFromSearch() {
  const qs = queryString.parse(window.location.search);
  if (qs.qrcodes == null) {
    return [];
  }
  const qrcodes = qs.qrcodes as string;
  const individualCodes = queryString.parse(qrcodes, {
    arrayFormat: "comma",
  });

  return Object.keys(individualCodes).map((label) => {
    const coordinates = individualCodes[label] as string[];

    const qr: QRCode = {
      data: label,
      position: [
        parseFloat(coordinates[0]),
        parseFloat(coordinates[1]),
        parseFloat(coordinates[2]),
      ],
      source: "publisher",
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      model_id: null as any,
      team_id: null as any,
      code_id: db.collection("id").doc().id,
    };
    return qr;
  });
}

/**
 * Provides markers form the useMarkers hook.
 * Or fetches if they are not available
 */
export function useOrFetchMarkers(model: Model) {
  const { markers } = useMarkers();

  const [codes, setCodes] = useState<QRCode[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    setCodes(markers.map((m) => m.code));
  }, [markers]);

  const { team_id, model_id } = model;

  useEffect(() => {
    if (markers.length === 0) {
      setFetching(true);
      return db
        .collection("qrcodes")
        .where("team_id", "==", team_id)
        .where("model_id", "==", model_id)
        .onSnapshot((snapshot) => {
          setCodes(snapshot.docs.map((d) => d.data() as QRCode));
          setFetching(false);
        });
    }
  }, [markers, team_id, model_id]);

  return { qrcodes: codes, fetching: fetching };
}
