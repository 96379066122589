/**
 * Close mirror to window.alert using a styled ui
 */

import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  useMediaQuery,
} from "@mui/material";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export interface Alerts {
  alert: (message: string, title?: string) => Promise<void>;
  prompt: (
    message: string,
    placeholder?: string,
    title?: string,
    options?: {
      password?: boolean;
    }
  ) => Promise<string | null>;
  snack: (message: string) => any;
  confirm: (message: string, title?: string) => Promise<boolean | null>;
}
export const AlertsContext = createContext<Alerts>({} as Alerts);
export const useAlerts = () => useContext(AlertsContext);

export function AlertsProvider({ children }: { children: ReactNode }) {
  const [promptResolver, setPromptResolver] =
    useState<(result: string | null) => void>();
  const [confirmResolver, setConfirmResolver] =
    useState<(result: boolean | null) => void>();
  const [alertResolver, setAlertResolver] = useState<() => void>();

  const [alertContent, setAlertContent] = useState<{
    message: string;
    title: string;
  }>({ message: "Message", title: "Alert" });

  const [promptContent, setPromptContent] = useState<{
    message: string;
    title: string;
    response: string | null;
    isPassword: boolean;
  }>({
    message: "Message",
    title: "Prompt",
    response: null,
    isPassword: false,
  });

  const [confirmContent, setConfirmContent] = useState<{
    message: string;
    title: string;
    response: boolean | null;
  }>({
    message: "Message",
    title: "Confirm",
    response: null,
  });

  const [snackMessage, setSnackMessage] = useState<string>();
  const [snackTime, setSnackTime] = useState<number>();

  const alert = useCallback((message: string, title?: string) => {
    setAlertContent({ message, title: title ?? "Alert" });
    return new Promise<void>((resolve, reject) => {
      setAlertResolver(() => resolve);
    });
  }, []);

  const prompt = useCallback(
    (
      message: string,
      placeholder?: string,
      title?: string,
      options?: {
        password?: boolean;
      }
    ) => {
      setPromptContent({
        message,
        title: title ?? "Prompt",
        response: placeholder ?? null,
        isPassword: options?.password ?? false,
      });
      return new Promise<string | null>((resolve, reject) => {
        setPromptResolver(() => resolve);
      });
    },
    []
  );
  const confirm = useCallback((message: string, title?: string) => {
    setConfirmContent({ message, title: title ?? "Confirm", response: null });
    return new Promise<boolean | null>((resolve, reject) => {
      setConfirmResolver(() => resolve);
    });
  }, []);

  const snack = useCallback((message: string) => {
    setSnackMessage(message);
    setSnackTime(Date.now());
  }, []);

  const fullscreen = useMediaQuery("(max-width:600px),(max-height:600px)");

  return (
    <AlertsContext.Provider value={{ alert, prompt, snack, confirm }}>
      <Snackbar
        id="snack"
        key={snackTime}
        message={snackMessage}
        open={snackMessage != null}
        onClose={() => setSnackMessage(undefined)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />

      <Dialog
        id="alert"
        fullScreen={fullscreen}
        open={alertResolver != null}
        PaperProps={{
          sx: styles.paper,
        }}
        onClose={() => {
          if (alertResolver) {
            alertResolver();
            setAlertResolver(undefined);
          }
        }}
      >
        <DialogTitle>{alertContent.title}</DialogTitle>
        <DialogContent sx={styles.content}>
          {alertContent.message}
        </DialogContent>
        <DialogActions>
          <EnterButton
            onClick={() => {
              if (alertResolver) {
                alertResolver();
                setAlertResolver(undefined);
              }
            }}
          >
            OK
          </EnterButton>
        </DialogActions>
      </Dialog>

      <Dialog
        id="prompt"
        fullScreen={fullscreen}
        open={promptResolver != null}
        PaperProps={{
          sx: styles.paper,
        }}
        onClose={() => {
          if (promptResolver) {
            promptResolver(promptContent.response);
            setPromptResolver(undefined);
          }
        }}
      >
        <DialogTitle>{promptContent.title}</DialogTitle>
        <DialogContent sx={styles.content}>
          {promptContent.message}
          <TextField
            value={promptContent.response ?? ""}
            variant="outlined"
            size="small"
            type={promptContent.isPassword ? "password" : "text"}
            onChange={(ev) => {
              setPromptContent((p) => ({ ...p, response: ev.target.value }));
            }}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (promptResolver) {
                promptResolver(null);
                setPromptResolver(undefined);
              }
            }}
          >
            Cancel
          </Button>
          <EnterButton
            onClick={() => {
              if (promptResolver) {
                promptResolver(promptContent.response);
                setPromptResolver(undefined);
              }
            }}
          >
            OK
          </EnterButton>
        </DialogActions>
      </Dialog>

      <Dialog
        id="confirm"
        fullScreen={fullscreen}
        open={confirmResolver != null}
        PaperProps={{
          sx: styles.paper,
        }}
        onClose={() => {
          if (confirmResolver) {
            confirmResolver(false);
            setConfirmResolver(undefined);
          }
        }}
      >
        <DialogTitle>{confirmContent.title}</DialogTitle>
        <DialogContent sx={styles.content}>
          {confirmContent.message}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (confirmResolver) {
                confirmResolver(false);
                setConfirmResolver(undefined);
              }
            }}
          >
            Cancel
          </Button>
          <EnterButton
            onClick={() => {
              if (confirmResolver) {
                confirmResolver(true);
                setConfirmResolver(undefined);
              }
            }}
          >
            Confirm
          </EnterButton>
        </DialogActions>
      </Dialog>

      {children}
    </AlertsContext.Provider>
  );
}

function EnterButton({ onClick, ...other }: ButtonProps) {
  useEffect(() => {
    function onKeyPress(ev: KeyboardEvent) {
      if (ev.key === "Enter" || ev.key === "NumpadEnter") {
        if (onClick) {
          onClick(ev as any);
        }
      }
    }

    window.addEventListener("keypress", onKeyPress, false);

    return () => {
      window.removeEventListener("keypress", onKeyPress, false);
    };
  }, [onClick]);

  return <Button {...other} onClick={onClick} />;
}

const styles = {
  paper: {
    minWidth: "min(400px, 80vw)",
    minHeight: "min(200px, 80vh)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
} as const;
