import React, { createContext, useCallback, useContext, useState } from "react";

type ThumbnailCaptureCallback = (args: {
  size: number;
  format: "image/jpeg" | "image/png";
}) => Promise<string>;

type CaptureContextType = {
  /**
   * Calls the callback specified in @function setCaptureThumbnail
   */
  captureThumbnail: ThumbnailCaptureCallback | undefined;
  /**
   * Used to define a callback function that captures a thumbnail
   */
  setCaptureThumbnail: (callback: ThumbnailCaptureCallback | undefined) => any;
};

export const CaptureContext = createContext<CaptureContextType>(
  {} as CaptureContextType
);
export const useCaptureContext = () => useContext(CaptureContext);

export default useCaptureContext;

export function CaptureContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [callback, setCallback] = useState<ThumbnailCaptureCallback>();

  const setCallbackMemo = useCallback(
    (args: ThumbnailCaptureCallback | undefined) => setCallback(() => args),
    []
  );

  return (
    <CaptureContext.Provider
      value={{
        captureThumbnail: callback,
        setCaptureThumbnail: setCallbackMemo,
      }}
    >
      {children}
    </CaptureContext.Provider>
  );
}
