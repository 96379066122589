import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { logEvent, useClaims } from "../Auth";
import { useEffect, useState } from "react";
import { Model, QRCode } from "../Types";
import { CropFree } from "@mui/icons-material";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useMarkers } from "./useMarkers";
import { useAlerts } from "Alerts";
import WorkerButton from "components/WorkerButton";

const db = firebase.firestore();

export default function MarkerImportView({
  open,
  onClose,
  modelId,
}: {
  open: boolean;
  onClose: () => any;
  modelId?: string;
}) {
  const { addMarker } = useMarkers();
  const claims = useClaims();
  const teamId = claims?.team_id;
  const { alert } = useAlerts();

  const [models, setModels] = useState<Model[]>();

  const [working, setWorking] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    if (teamId == null) return;

    return db
      .collection("models")
      .where("team_id", "==", teamId)
      .where("marker_count", ">", 0)
      .onSnapshot((snapshot) => {
        setModels(
          snapshot.docs
            .map((doc) => doc.data() as Model)
            .filter((m) => modelId == null || m.model_id !== modelId)
            .sort(
              (a, b) =>
                (b.created_at as firebase.firestore.Timestamp).seconds -
                (a.created_at as firebase.firestore.Timestamp).seconds
            )
        );
      });
  }, [teamId, modelId]);

  useEffect(() => {
    if (selectedIndex < 0 && (models?.length ?? 0) > 0) {
      setSelectedIndex(0);
    }
  }, [models, selectedIndex]);

  const selectedModel =
    selectedIndex >= 0 ? (models![selectedIndex] as Model) : undefined;

  async function onConfirm() {
    if (!selectedModel) return;

    setWorking(true);

    const existingCodes = (
      await db
        .collection("qrcodes")
        .where("team_id", "==", teamId)
        .where("model_id", "==", selectedModel.model_id)
        .get()
    ).docs.map((d) => d.data() as QRCode);

    if (existingCodes.length === 0) {
      console.error("Model did not have any QR codes");
      alert(
        "We didn't find any QR codes attached to this model. Please contact support if issues persist."
      );
      return;
    }

    const promises = existingCodes.map((existing) => {
      const code: QRCode = {
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        data: existing.data,
        position: existing.position,
        source: "clone",
        model_id: modelId as any,
        team_id: teamId as any,
        code_id: db.collection("id").doc().id,
      };

      return addMarker(code)
        .then(() => true)
        .catch(() => false);
    });

    try {
      // Write each individually in case some fail (instead of failing the entire batch)
      const results = await Promise.all(promises);
      const failed = results.filter((x) => !x).length;
      const succeeded = results.length - failed;

      if (failed > 1) {
        alert(
          `Imported ${succeeded} Marker${
            succeeded === 1 ? "" : "s"
          }. ${failed} Markers were unable to import as their identifiers are already used in this model. Each Marker must have a unique identifier.`,
          `Import Error`
        );
      } else if (failed === 1) {
        alert(
          `Imported ${succeeded} Marker${
            succeeded === 1 ? "" : "s"
          }. ${failed} marker was unable to import as it's identifier is already used in this model. Each Marker must have a unique identifier.`,
          `Import Error`
        );
      } else {
        alert(
          `Successfully imported ${results.length} Markers.`,
          "Import Success"
        );
      }
      logEvent("registration_code_cloned", { amount: existingCodes.length });
    } finally {
      setWorking(false);
    }
    onClose();
  }
  const fullScreen = useMediaQuery("(max-width:600px)");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted={false}
      fullScreen={fullScreen}
    >
      <DialogTitle>Import Markers</DialogTitle>
      <DialogContent>
        <FormGroup>
          <Typography variant="body2" gutterBottom>
            Select a model to import markers from:
          </Typography>
          <Select
            variant="outlined"
            size="small"
            disabled={(models?.length ?? 0) <= 0 || working}
            placeholder="Select Model"
            defaultValue={"Hello"}
            value={
              models === undefined
                ? "loading"
                : models.length === 0
                ? "empty"
                : selectedIndex < 0
                ? ""
                : selectedIndex
            }
            onChange={(ev) => {
              setSelectedIndex(ev.target.value as any);
            }}
            MenuProps={{ style: { display: "flex" } }}
          >
            {models === undefined && (
              <MenuItem value="loading">Loading...</MenuItem>
            )}
            {(models?.length ?? -1) === 0 && (
              <MenuItem value="empty">No suitable models found</MenuItem>
            )}

            {models?.map((model, ind) => {
              return (
                <MenuItem key={ind} value={ind}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      id="icon"
                      component="div"
                      sx={{
                        pr: 0.5,
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        width: 45,
                        height: 45,
                      }}
                    >
                      <CropFree
                        sx={{ width: 40, height: 35, color: "grey.300" }}
                      />
                      <Box
                        component="div"
                        justifyContent="center"
                        alignItems="center"
                        width={40}
                        height={40}
                        position="absolute"
                        display="flex"
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "primary.main",
                            fontWeight: "bold",
                            mb: "-2px",
                          }}
                        >
                          {model.marker_count}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      component="div"
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {model.model_name ?? "Unnamed"}
                      </Typography>

                      <Typography variant="caption" sx={{ color: "grey.400" }}>
                        {(
                          (model.created_at ??
                            firebase.firestore.Timestamp.now()) as any
                        )
                          .toDate()
                          .toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={styles.buttonNeutral} disabled={working}>
          Cancel
        </Button>
        <WorkerButton
          onClick={onConfirm}
          sx={styles.buttonPositive}
          disabled={(models?.length ?? 0) === 0}
          working={working}
        >
          {!selectedModel && "Import Markers"}
          {selectedModel &&
            (selectedModel.marker_count === 1
              ? "Import Marker"
              : `Import ${selectedModel.marker_count} Markers`)}
        </WorkerButton>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  buttonPositive: {
    color: "primary.main",
  },
  buttonNeutral: {
    color: "grey.500",
  },
  buttonNegative: {
    color: "error.main",
  },
} as const;
