import React from "react";
import { FallbackProps } from "react-error-boundary";
import { Link, Typography, Box } from "@mui/material";
import { SentimentVeryDissatisfied as ErrorIcon } from "@mui/icons-material";

export default function ErrorFallback({ error }: FallbackProps) {
  return (
    <ErrorFallBackView message={error?.message ?? "Something went wrong..."} />
  );
}

export function ErrorFallBackView({
  title,
  message,
  stack,
}: {
  title?: string;
  message: string;
  stack?: string;
}) {
  const [showMore, setShowMore] = React.useState<boolean>(false);

  return (
    <Box component="div" sx={styles.container}>
      <Box component="div" sx={styles.inner}>
        <ErrorIcon sx={styles.icon} />
        <Typography variant="h6">
          {title ?? "Something went wrong..."}
        </Typography>
        <Typography variant="body1">{message}</Typography>
        {stack && (
          <Typography variant="caption">
            <Link onClick={() => setShowMore(!showMore)}>
              Technical information
            </Link>
          </Typography>
        )}
        {stack && showMore && (
          <Typography variant="caption" sx={styles.stack}>
            {stack.split("\n").map((x) => (
              <span>{x}</span>
            ))}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
    margin: "auto",
  },
  inner: {
    padding: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 144,
    height: 144,
    margin: 1,
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    width: "100%",
  },
} as const;
