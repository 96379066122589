import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Model } from "../Types";
const bucket = firebase.storage();

export const useModelUri = (model: Model) => {
  const [uri, setUri] = useState<string>();
  const { storage_path } = model;
  useEffect(() => {
    let cancelled = false;
    (async () => {
      const res = await fetchStorageUri(storage_path);
      if (!cancelled) {
        setUri(res);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [storage_path]);
  return uri;
};

export const useModelThumbnailUri = (model: Model) => {
  const [uri, setUri] = useState<string>();
  const { thumbnail_storage_path } = model;
  useEffect(() => {
    let cancelled = false;
    (async () => {
      const res = await fetchStorageUri(thumbnail_storage_path);
      if (!cancelled) {
        setUri(res);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [thumbnail_storage_path]);
  return uri;
};

export async function fetchStorageUri(path: string) {
  return await bucket.ref(path).getDownloadURL();
}
