import { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Model } from "Types";
import { ErrorBoundary } from "react-error-boundary";
import ModelViewLoader from "./ModelViewLoader";
import ErrorFallBack from "components/ErrorFallBack";
import SuspenseIndeterminate from "components/SuspenseIndeterminate";
import Overlay from "View/Overlay";
import { MarkersContextProvider } from "../Markers/useMarkers";
import { CaptureContextProvider } from "hooks/useCaptureContext";
import { QRModelCodeViewButton } from "./QRModelCodeView";
import { MarkerOptionsButton } from "Markers/MarkerOptionsView";
import { fetchStorageUri } from "hooks/useModelUri";
const db = firebase.firestore();

/**
 * Model viewer that loads based on firestore records
 */
export default function StorageModelViewer() {
  const { model_id } = useParams<{ model_id: string }>();
  const modelFetch = fetchModel(model_id);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallBack}>
        <Suspense fallback={<SuspenseIndeterminate />}>
          <SuspenseViewer modelFetch={modelFetch} />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

/**
 * Suspenseful component
 */
function SuspenseViewer({
  modelFetch,
}: {
  modelFetch: () => { model: Model; downloadUrl: string };
}) {
  const fetch = modelFetch();

  useEffect(() => {
    console.info(fetch.model);
  }, [fetch]);

  const [controlPanelRef, setControlPanelRef] = useState<HTMLDivElement | null>(
    null
  );

  return (
    <MarkersContextProvider model={fetch.model}>
      <CaptureContextProvider>
        <ModelViewLoader
          uri={fetch.downloadUrl}
          format="gltf"
          modelId={fetch.model.model_id}
          uiDomElement={controlPanelRef}
        />
        <Overlay
          ref={(r) => {
            setControlPanelRef(r);
          }}
        >
          {fetch && <QRModelCodeViewButton model={fetch.model} />}
          <MarkerOptionsButton />
        </Overlay>
      </CaptureContextProvider>
    </MarkersContextProvider>
  );
}

/**
 * Suspenseful fetch
 */
function fetchModel(model_id?: string) {
  let status: "init" | "error" | "done" = "init";
  let result: { model: Model; downloadUrl: string } | undefined = undefined;
  let error: Error | undefined = undefined;

  const fetch = (async () => {
    if (!model_id) {
      status = "error";
      return;
    }

    try {
      const model = await db.doc(`models/${model_id}`).get();
      if (!model.exists) {
        status = "error";
        error = new Error(`No model exists with id ${model_id}`);
        return;
      }
      const data = model.data() as Model;
      const download_url = await fetchStorageUri(data.storage_path);
      result = { model: data, downloadUrl: download_url };
      status = "done";
      return;
    } catch (err) {
      status = "error";
      console.error("Unable to retrieve model", model_id);
      error = err;
      return;
    }
  })();

  return () => {
    switch (status) {
      case "init":
        throw fetch;
      case "error":
        throw error;
      case "done":
        return result!;
    }
  };
}
