import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { AccountHeader } from "./AccountCollapse";
import { ValidFileExtensions } from "../View/FileFormats";
import { Launch } from "@mui/icons-material";
import { redirectToPreview } from "View/PreviewModelViewer";
import { useNavigate } from "react-router-dom";

export function DropInstructionsView() {
  const [pickerRef, setPickerRef] = useState<HTMLInputElement>();
  const navigate = useNavigate();

  return (
    <>
      <AccountHeader
        title="Publish a Model"
        description="Use our one click plugins, drag and drop a model, or tap the button below to choose a file."
        button={
          <Button
            color="primary"
            variant="contained"
            href="https://twinbuild.com/download"
            target="_blank"
            endIcon={<Launch />}
          >
            Get Plugins
          </Button>
        }
      >
        <input
          type="file"
          accept={ValidFileExtensions.map((x) => "." + x).join(",")}
          ref={(r) => {
            if (r) setPickerRef(r);
          }}
          style={{
            display: "hidden",
            visibility: "hidden",
            width: 0,
            height: 0,
            position: "absolute",
          }}
          onChange={(ev) => {
            if ((ev.target.files?.length ?? 0) === 1) {
              const file = ev.target.files![0];
              redirectToPreview(file, (uri) => {
                navigate(uri);
              });
            }
          }}
        />
        <Typography variant="caption" sx={styles.dropBox}>
          <strong> Drag and drop </strong>
          ifc, fbx, gltf, glb, obj, ply
        </Typography>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={() => {
            pickerRef!.click();
          }}
        >
          Select file
        </Button>
      </AccountHeader>
    </>
  );
}

export default DropInstructionsView;

const styles = {
  button: {
    maxWidth: 200,
  },
  dropBox: {
    width: { xs: 0, sm: 200 },
    height: { xs: 0, sm: 100 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "grey.200",
    borderColor: "grey.500",
    borderWidth: 2,
    borderStyle: "dashed",
    borderRadius: 1,
    marginBottom: 0.5,
    visibility: { xs: "hidden", sm: "visible" },
    overflow: { xs: "hidden", sm: "visible" },
    pointerEvents: "none",
  },
} as const;
