import { Box, Fade, Typography } from "@mui/material";

/**
 * Pure component showing a load screen
 */
export default function LoadScreen({ progress }: { progress: number }) {
  return (
    <Fade in={true}>
      <Box component="div" sx={styles.loaderContainer}>
        <Typography variant="h4" sx={{ mb: 0.5, color: "grey.400" }}>
          {progress.toFixed(2)}%
        </Typography>
        <Typography variant="caption" sx={{ color: "grey.300" }}>
          {progress === 100 ? "Processing..." : "Loading..."}
        </Typography>
      </Box>
    </Fade>
  );
}

const styles = {
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
} as const;
