import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import useQueryString from "hooks/useQueryString";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
const db = firebase.firestore();

export function UpdateCheck() {
  const {
    source,
    plugin_version,
    os,
    rhino_version,
    revit_version,
  } = useQueryString<{
    source: "rhino" | "revit" | undefined;
    plugin_version: string | undefined;
    os: string | undefined;
    rhino_version: string | undefined;
    revit_version: string | undefined;
  }>();

  const [updateVersion, setUpdateVersion] = useState<string>();

  useEffect(() => {
    if (source === "rhino" && plugin_version && rhino_version && os) {
      let cancelled = false;

      (async () => {
        try {
          const docs = await db
            .collection("plugins/rhino/versions")
            .where("compatibility", "array-contains", parseInt(rhino_version))
            .where("os", "==", os)
            .orderBy("created_at", "asc")
            .limitToLast(1)
            .get();
          if (docs.empty) {
            console.warn("Unable to retrieve latest plugin version");
            return;
          }
          const latest = docs.docs[0].data().version; // Fails in dev mode as no plugins are available.
          if (cancelled) return;
          if (isUpdateAvailable(plugin_version, latest)) {
            setUpdateVersion(latest);
          }
        } catch (err) {
          console.error("Unable to check for updates", err);
        }
      })();

      return () => {
        cancelled = true;
      };
    } else if (source === "revit" && plugin_version && revit_version && os) {
      let cancelled = false;

      (async () => {
        try {
          const docs = await db
            .collection("plugins/revit/versions")
            .where("compatibility", "array-contains", parseInt(revit_version))
            .orderBy("created_at", "asc")
            .limitToLast(1)
            .get();

          if (docs.empty) {
            console.warn("Unable to retrieve latest plugin version");
            return;
          }

          const latest = docs.docs[0].data().version;

          if (cancelled) return;
          if (isUpdateAvailable(plugin_version, latest)) {
            setUpdateVersion(latest);
          }
        } catch (err) {
          console.error("Unable to check for updates", err);
        }
      })();

      return () => {
        cancelled = true;
      };
    }
  }, [source, plugin_version, os, revit_version, rhino_version]);

  return (
    <Dialog open={updateVersion != null}>
      <DialogTitle>Update Available</DialogTitle>
      <DialogContent>
        An update is available to the{" "}
        {revit_version == null ? "Rhino" : "Revit"} publisher. For best results,
        please update now.
        <br />
        <br />
        <Typography variant="caption">
          Your version: {plugin_version}
          <br />
          Latest version: {updateVersion}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setUpdateVersion(undefined)}
          sx={styles.skipButton}
        >
          Later
        </Button>
        <Button
          onClick={() => {
            setUpdateVersion(undefined);
            window.open("https://twinbuild.com/download", "_blank");
          }}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  skipButton: {
    color: "grey.500",
  },
} as const;

/**
 * Returns true if the server version is newer than the local version
 */
function isUpdateAvailable(userVersion: string, serverVersion: string) {
  console.info("Checking for plugin updates for user version", userVersion);
  const localVersion = userVersion.split(".");

  if (localVersion.length !== 4) {
    console.error("invalid version code");
    return undefined;
  }

  // Updates will be suggested if under this limit
  const remoteVersion = serverVersion.split(".");

  for (let i = 0; i < 4; i++) {
    if (parseFloat(localVersion[i]) < parseFloat(remoteVersion[i])) {
      return true;
    }
    // Users version is newer than server version
    else if (parseFloat(localVersion[i]) > parseFloat(remoteVersion[i])) break;
  }

  return false;
}
