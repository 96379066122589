import Drop from "Upload/Drop";
import Pipe from "Upload/Pipe";
import { Routes, Route } from "react-router-dom";
import PreviewModelViewer from "./View/PreviewModelViewer";
import StorageModelViewer from "./View/StorageModelViewer";
import AccountFrame from "./Account/AccountFrame";
import ModelsView from "Account/ModelsView";
import ModelsListView from "Account/ModelsListView";
import Footer from "./Footer";

function App() {
  return (
    <>
      <Drop />
      <Pipe />

      <Routes>
        <Route
          path="/preview"
          element={
            <AccountFrame required={false}>
              <PreviewModelViewer />
            </AccountFrame>
          }
        />
        <Route
          path="/models/:model_id"
          element={
            <AccountFrame required={true}>
              <StorageModelViewer />
            </AccountFrame>
          }
        />
        <Route
          path="/models"
          element={
            <>
              <AccountFrame narrow required={false}>
                <ModelsListView />
              </AccountFrame>
              <Footer />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <AccountFrame narrow required={false}>
                <ModelsView />
              </AccountFrame>
              <Footer />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
