import { useAuth } from "Auth";
import { forwardRef, ReactNode } from "react";
import { Fade, Box } from "@mui/material";
/**
 * View and controller for editing an existing model
 */
interface OverlayProps {
  children: ReactNode[];
}
const Overlay = forwardRef<HTMLDivElement, OverlayProps>((props, ref) => {
  const auth = useAuth();
  const { children } = props;

  return (
    <Fade in={auth !== undefined}>
      <Box component="div" sx={styles.overlay}>
        <Box component="div" sx={styles.container} ref={ref}>
          {children.map(
            (child, ind) =>
              child && (
                <Box component="div" key={ind} sx={styles.item}>
                  {child}
                </Box>
              )
          )}
        </Box>
      </Box>
    </Fade>
  );
});

export default Overlay;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  overlay: {
    position: "absolute",
    right: 0,
    bottom: 0,
    margin: 1,
    zIndex: 100,
  },
  item: {
    margin: 0.25,
  },
} as const;
