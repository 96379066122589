import ModelsListView from "./ModelsListView";
import DropInstructionsView from "./DropInstructionsView";
import AccountLinkView from "./AccountLinkView";

export function ModelsView() {
  return (
    <>
      <DropInstructionsView />
      <ModelsListView />
      <AccountLinkView />
    </>
  );
}

export default ModelsView;
