import firebase from "firebase/compat/app";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Typography,
  Box,
  Link,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  TableHead,
  TableContainer,
  Collapse,
  TableBody,
  IconButton,
  Divider,
} from "@mui/material";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  ViewInAr,
} from "@mui/icons-material";
import { useMemo, useState, useCallback } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useEffect } from "react";
import { AnimatedLogo } from "components/Loaders";
import config from "config";
import ms from "ms";
import queryString from "query-string";
import { Model } from "Types";
import {
  Launch,
  Print,
  Error as Warning,
  NotificationsActive,
} from "@mui/icons-material";
import { printCodes } from "Markers/MarkerOptionsView";
import { AccountHeader } from "Account/AccountCollapse";
import WorkerButton from "components/WorkerButton";
import { useAlerts } from "Alerts";
import { logEvent } from "Auth";
import { fetchStorageUri } from "../hooks/useModelUri";
import { NotificationChannel } from "Types/NotificationChannel";
import { useOrFetchMarkers } from "Markers/useMarkers";

const db = firebase.firestore();
const functions = firebase.functions();

type CreateModelTokenRequest = {
  /**
   * Id of the model
   */
  model_id: string;
  /**
   * Token expiry using ms. Defaults to 5m.
   */
  token_duration?: string;
};

type CreateModelTokenResponse = {
  /**
   * The token that can be used to access the model
   */
  token: string;
  /**
   * Expiry time of the token in ms since epoch
   */
  token_expiry: number;
  /**
   * Id used for referencing this token to the user
   */
  token_ref: string;
};

export async function fetchModelToken(request: CreateModelTokenRequest) {
  return (await functions.httpsCallable("createModelToken")(request))
    .data as CreateModelTokenResponse;
}

export function QRModelCodeView({
  open,
  onClose,
  model,
}: {
  open: boolean;
  onClose: () => any;
  model: Model;
}) {
  const { model_id, team_id } = model;

  const { alert } = useAlerts();
  const [data, setData] = useState<CreateModelTokenResponse>();
  const [ticks, setTicks] = useState<number>(0);
  const [printing, setPrinting] = useState<boolean>(false);

  const { qrcodes: markers, fetching: fetchingMarkers } =
    useOrFetchMarkers(model);

  const generate = useCallback(async () => {
    setData(undefined);
    const token = await fetchModelToken({
      model_id: model_id,
    });
    setData(token);
  }, [model_id]);

  useEffect(() => {
    if (open) {
      generate();
    }
  }, [open, generate]);

  const uri = useMemo(() => {
    return data != null && `${config.protocol}://m/${model_id}?t=${data.token}`;
  }, [data, model_id]);

  useEffect(() => {
    if (uri) {
      console.info("Showing QR code", uri, uri.length);
    }
  }, [uri]);

  useEffect(() => {
    if (data) {
      // Close the code if it expires
      const finish = setTimeout(() => {
        onClose();
      }, data.token_expiry - Date.now());

      // Update the expiry time every second
      const interval = setInterval(() => {
        setTicks((p) => p + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
        clearTimeout(finish);
      };
    }
  }, [data, onClose]);

  const printLink = useCallback(async () => {
    setPrinting(true);

    try {
      const response = await fetchModelToken({
        model_id: model.model_id,
        token_duration: "1y",
      });

      const deeplink = `${config.protocol}://m/${model.model_id}?t=${response.token}`;

      const hash = {
        type: "model",
        qr: deeplink,
        model_name: model.model_name ?? `Model ${model.model_id}`,
        model_thumbnail: await fetchStorageUri(model.thumbnail_storage_path),
        model_created: (
          model.created_at as firebase.firestore.Timestamp
        ).toMillis(),
        model_creator: model.user_name ?? model.user_email,
        token_expires: response.token_expiry,
        token_reference: response.token_ref,
      };

      logEvent("print_qr_link", { model: model.model_id });
      const qrUri = `${config.qrplotter}#${queryString.stringify(hash)}`;
      console.info("Launch QR Plotter", qrUri);

      // Safari doesn't like window.open called not in onclick
      const a = document.createElement("a");
      a.setAttribute("href", qrUri);
      a.setAttribute("target", "_blank");
      a.setAttribute("rel", "noreferrer");
      a.click();
      a.remove();
    } catch (err) {
      console.error("Error generating token", err);
      setPrinting(false);
    }
    setPrinting(false);
  }, [model]);

  const printMarkers = useCallback(async () => {
    if (markers.length === 0) {
      await alert(
        "We were unable to find QR codes to print. Please contact support if issues persist.",
        "Error"
      );
    } else {
      printCodes(markers.map((m) => m.data));
    }
  }, [alert, markers]);

  const markerCount = markers.length;

  return (
    <>
      <Dialog open={open} fullScreen onClose={onClose}>
        <DialogTitle>{model.model_name ?? "View in AR"}</DialogTitle>
        <DialogContent sx={styles.container}>
          <Box component="div" sx={styles.contentOuter}>
            {!uri && (
              <>
                <Box component="div" sx={styles.qrContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1 1"
                    style={{
                      width: "100%",
                      height: "calc(100% - 36px)",
                      display: "flex",
                      aspectRatio: "1/1",
                      margin: 0,
                      padding: 0,
                    }}
                  />
                  <Fade in={true} timeout={1000}>
                    <Box
                      component="div"
                      sx={{
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <AnimatedLogo />
                      <Typography variant="caption" sx={{ color: "grey.400" }}>
                        Fetching secure code...
                      </Typography>
                    </Box>
                  </Fade>
                </Box>
              </>
            )}
            {uri && (
              <Fade in={uri != null} timeout={1000}>
                <Box component="div" sx={styles.qrContainer}>
                  <QRCodeSVG
                    value={uri ?? ""}
                    size={960}
                    style={{
                      width: "100%",
                      height: "calc(100% - 36px)",
                      display: "flex",
                      aspectRatio: "1/1",
                    }}
                    level="L"
                  />
                  <Typography
                    key={ticks}
                    variant="caption"
                    sx={styles.caption}
                    gutterBottom
                  >
                    Tap this Link on your HoloLens 2 to launch the model. This
                    code will expire in{" "}
                    {ms((data?.token_expiry ?? 0) - Date.now(), {
                      long: true,
                    })}
                    .{" "}
                    <Link
                      sx={styles.link}
                      target="_blank"
                      href="https://docs.twinbuild.com/twinbuild-hololens-viewer/hololens-connection-codes.html"
                      rel="noreferrer"
                    >
                      Having trouble scanning?
                    </Link>
                  </Typography>
                </Box>
              </Fade>
            )}
            <Fade in={true} appear timeout={1000}>
              <Box component="div" sx={styles.instructionsContainer}>
                <Box component="div" sx={styles.instructions}>
                  {markerCount >= 2 && (
                    <AccountHeader
                      disableDivider
                      title={`${markerCount} Markers`}
                      description={
                        <>
                          Print and accurately place these Markers on your
                          worksite.{" "}
                          <Link
                            href="https://docs.twinbuild.com/model-placement/"
                            target="_blank"
                          >
                            Learn More.
                          </Link>
                        </>
                      }
                      button={
                        <WorkerButton
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            logEvent("print_qr_markers", {
                              count: markerCount,
                            });
                            printMarkers();
                          }}
                          endIcon={<Print />}
                          working={fetchingMarkers}
                          disabled={markerCount === 0}
                        >
                          Print
                        </WorkerButton>
                      }
                    />
                  )}
                  {markerCount < 2 && (
                    <AccountHeader
                      disableDivider
                      title={
                        <Box component="div" sx={styles.error}>
                          <Warning sx={styles.textIcon} />
                          {markerCount === 0
                            ? "No Markers"
                            : markerCount === 1
                            ? "1 Marker"
                            : `${markerCount} Markers`}
                        </Box>
                      }
                      description={
                        <>
                          This model contains {markerCount} Marker
                          {markerCount === 1 ? "" : "s"}. You can proceed to
                          view the model, but you will not be able to accurately
                          locate it in physical space. For best results, use at
                          least 3 Markers.{" "}
                          <Link
                            href="https://docs.twinbuild.com/model-placement/"
                            target="_blank"
                          >
                            Learn More.
                          </Link>
                        </>
                      }
                      button={
                        markerCount === 0 ? (
                          <Button
                            color="primary"
                            variant="outlined"
                            endIcon={<Launch />}
                            href="https://docs.twinbuild.com/model-placement/"
                            target="_blank"
                          >
                            Docs
                          </Button>
                        ) : (
                          <WorkerButton
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              logEvent("print_qr_markers", {
                                count: markerCount,
                              });
                              printMarkers();
                            }}
                            endIcon={<Print />}
                            working={fetchingMarkers}
                            disabled={markerCount === 0}
                          >
                            Print
                          </WorkerButton>
                        )
                      }
                    />
                  )}
                  <AccountHeader
                    title="Link"
                    description={
                      <>
                        Print this Link to share model access. Anyone with
                        access to the Link will be able to view the model. To
                        view this model offline, ensure it has been loaded at
                        least once beforehand.{" "}
                        <Link
                          href="https://docs.twinbuild.com/twinbuild-hololens-viewer/hololens-cached.html"
                          target="_blank"
                        >
                          Learn More.
                        </Link>
                      </>
                    }
                    button={
                      <WorkerButton
                        color="primary"
                        variant="outlined"
                        endIcon={<Print />}
                        onClick={printLink}
                        working={printing}
                      >
                        Print
                      </WorkerButton>
                    }
                  />
                  <DeviceNotifications team_id={team_id} model_id={model_id} />
                </Box>
              </Box>
            </Fade>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

type NotifyDevicesRequest = {
  /**
   * The device IDs to notify. If none, all will be used
   */
  device_ids: string[];

  /**
   * The model ID to notify
   */
  model_id: string;
};

type NotifyDevicesResponse = {
  /**
   * Number of notifications that succeeded
   */
  succeeded: number;

  /**
   * Number of notifications that failed
   */
  failed: number;
};

async function notifyDevices(request: NotifyDevicesRequest) {
  return (await functions.httpsCallable("notifyDevices")(request))
    .data as NotifyDevicesResponse;
}

function DeviceNotifications({
  team_id,
  model_id,
}: {
  team_id: string;
  model_id: string;
}) {
  //const [devices, setDevices] = useState<(Device & { checked: boolean })[]>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { alert, snack } = useAlerts();

  const [devices, setDevices] = useState<
    {
      device_name: string;
      checked: boolean;
      device_id: string;
    }[]
  >([]);
  const [channels, setChannels] = useState<NotificationChannel[]>([]);

  useEffect(() => {
    return db
      .collection("notifications")
      .where("team_id", "==", team_id)
      .onSnapshot((snapshot) => {
        // Only update the collection when a channel is added or removed
        if (
          snapshot
            .docChanges()
            .filter(
              (change) => change.type === "added" || change.type === "removed"
            ).length === 0
        )
          return;

        setChannels(
          snapshot.docs
            .map((doc) => doc.data() as NotificationChannel)
            .filter(
              (channel) =>
                channel.wns_expiry != null && channel.wns_expiry > Date.now()
            )
            .sort((a, b) => b.wns_expiry - a.wns_expiry)
        );
      });
  }, [team_id]);

  useEffect(() => {
    const map: Record<
      string,
      {
        device_name: string;
        checked: boolean;
        device_id: string;
      }
    > = {};
    channels.forEach((channel) => {
      map[channel.device_id] = {
        checked: true,
        device_name: channel.device_name,
        device_id: channel.device_id,
      };
    });
    setDevices(Object.values(map));
  }, [channels]);

  const devicesToNotify = devices.filter((d) => d.checked);

  async function notify() {
    if (devicesToNotify.length === 0) return;
    setWorking(true);
    logEvent("notify_devices", { count: devicesToNotify.length });
    try {
      const response = await notifyDevices({
        model_id,
        device_ids: devicesToNotify.map((d) => d.device_id),
      });
      if ((response?.failed ?? 0) > 0) {
        snack(
          `Failed to notify ${response.failed} device${
            response.failed === 1 ? "" : "s"
          }. Please try again, or use the Link QR code to reconnect your device.`
        );
      } else {
        snack(
          `Successfully notified ${devicesToNotify.length} device${
            devicesToNotify.length === 1 ? "" : "s"
          }.`
        );
      }
    } catch (err) {
      console.error("Error notifying devices", err);
      await alert(
        "Unable to notify devices. Please contact support@twinbuild.com if issues persist."
      );
    } finally {
      setWorking(false);
    }
  }

  return (
    <AccountHeader
      title="Notifications"
      description="Send a notification to your devices with a prompt to launch the model."
      button={
        <WorkerButton
          variant="outlined"
          color="primary"
          working={working}
          disabled={devicesToNotify.length === 0}
          onClick={notify}
          endIcon={<NotificationsActive />}
        >
          Notify
        </WorkerButton>
      }
    >
      <Table size="small">
        <TableHead>
          <TableRow
            hover
            onClick={() => setCollapsed((p) => !p)}
            sx={{ userSelect: "none" }}
          >
            <TableCell padding="none" sx={{ width: 25, border: "none" }}>
              <Checkbox
                sx={{ p: "4px", ml: "4px" }}
                disableRipple
                indeterminate={
                  devicesToNotify.length > 0 &&
                  devicesToNotify.length !== devices.length
                }
                checked={
                  devices.length === devicesToNotify.length &&
                  devices.length > 0
                }
                disabled={working || devices.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDevices((p) => {
                    const check = devicesToNotify.length === 0;
                    p.forEach((d) => {
                      d.checked = check;
                    });
                    return [...p];
                  });
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                pr: 0.5,
                border: "none",
              }}
            >
              {devices.length > 0 ? (
                <>
                  {devicesToNotify.length} Device
                  {devicesToNotify.length === 1 ? " " : "s "} Selected
                </>
              ) : (
                <> No devices available </>
              )}
              <IconButton size="small">
                {!collapsed && <KeyboardArrowDownOutlined />}
                {collapsed && <KeyboardArrowUpOutlined />}
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Collapse in={collapsed}>
        <Divider />
        <TableContainer sx={{ pb: 1 }}>
          <Table>
            <TableBody>
              {devices.map((d, i) => (
                <TableRow
                  hover
                  key={d.device_id}
                  selected={d.checked}
                  onClick={() => {
                    if (working) return;
                    setDevices((p) => {
                      p.splice(i, 1, {
                        ...d,
                        checked: !d.checked,
                      });
                      return [...p];
                    });
                  }}
                  sx={{ userSelect: "none" }}
                >
                  <TableCell padding="none" sx={{ width: 25 }}>
                    <Checkbox
                      sx={{ p: "4px", ml: "4px" }}
                      checked={d.checked}
                      disableRipple
                      disabled={working}
                    />
                  </TableCell>
                  <TableCell sx={{ pt: 0, pb: 0, height: 25 }}>
                    <Typography variant="button">{d.device_name}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          variant="caption"
          sx={{
            color: "grey.500",
            maxWidth: "fit-content",
            display: "block",
          }}
        >
          Devices that have previously been used with a Link on this account
          will show up here. Devices must have been used in the past 30 days to
          be notified. Notifications expire after 10 minutes.{" "}
          <Link
            href="https://docs.twinbuild.com/twinbuild-web-viewer/notifications.html"
            target="_blank"
          >
            Learn More.
          </Link>
        </Typography>
      </Collapse>
    </AccountHeader>
  );
}

export function QRModelCodeViewButton({ model }: { model: Model }) {
  const [qrVisible, setQrVisible] = useState<boolean>(false);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setQrVisible(true)}
        startIcon={<ViewInAr />}
        style={{
          width: "100%",
          minWidth: 150,
        }}
      >
        <Box component="div" sx={{ width: "100%" }}>
          View in AR
        </Box>
      </Button>
      <QRModelCodeView
        open={qrVisible}
        onClose={() => setQrVisible(false)}
        model={model}
      />
    </>
  );
}

const styles = {
  container: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "relative",
  },
  contentOuter: {
    display: "flex",
    width: "100%",
    height: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: { xs: "column", md: "row" },
  },
  qrContainer: {
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    maxHeight: "100%",
    maxWidth: "100%",
    alignItems: "center",
    alignSelf: { xs: "center", md: "unset" },
    position: "relative",
  },
  caption: {
    marginTop: 0.5,
    color: "grey.500",
  },

  instructionsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    minWidth: { md: 400 },
  },
  instructions: {
    maxWidth: 960,
    width: "100%",
    padding: { xs: 0, md: 2 },
    marginTop: { xs: 4, md: 0 },
    maxHeight: "100%",
    overflowY: "auto",
  },
  error: {
    color: "error.main",
    display: "flex",
    alignItems: "center",
  },
  textIcon: {
    marginRight: 0.5,
  },
  link: {
    textDecoration: "none",
  },
} as const;

export default QRModelCodeView;
