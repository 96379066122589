import { useEffect, useMemo, useState } from "react";
import useQueryString from "../hooks/useQueryString";
import ModelViewLoader from "./ModelViewLoader";
import Upload from "Upload/Upload";
import * as qs from "query-string";
import { CaptureContextProvider } from "hooks/useCaptureContext";
import { UpdateCheck } from "Download/Update";
import { Model } from "Types";
import Overlay from "View/Overlay";
import { MAX_MODEL_SIZE_BYTES } from "../three/HealthWarningDialog";
import { ErrorFallBackView } from "components/ErrorFallBack";
import { MarkerOptionsButton } from "../Markers/MarkerOptionsView";
import { MarkersContextProvider } from "../Markers/useMarkers";
import ModelOptionsDialog, { ModelOptions } from "./ModelOptionsDialog";
import {
  LoaderTypes,
  MimeTypes,
  ValidFileExtension,
  ValidMimeType,
  ValidMimeTypes,
  ValidUploadMimeType,
} from "./FileFormats";
import { QRModelCodeViewButton } from "./QRModelCodeView";
import { ConvertedFile } from "three/useFileConverter";

/**
 * Model viewer that loads a model and uploads it based on a uri in the query string
 */

export default function Preview() {
  const { uri, type, name } = useQueryString<{
    uri?: string;
    type?: ValidMimeType;
    name?: string;
  }>();

  return <PreviewViewer key={uri} uri={uri} type={type} name={name} />;
}

function PreviewViewer({
  uri,
  type,
  name,
}: {
  uri?: string;
  type?: ValidMimeType;
  name?: string;
}) {
  const [uploadReadyUri, setUploadReadyUri] = useState<ConvertedFile>();
  const [upload, setUpload] = useState<{ model: Model }>();

  const { size } = useQueryString<{
    size: number | undefined;
  }>();

  const [blobUri, setBlobUri] = useState<string | null>();

  const [modelOptions, setModelOptions] = useState<ModelOptions | undefined>();

  const requiresOptions =
    type != null
      ? [
          "model/obj",
          "application/fbx",
          "application/dxf",
          "application/ply",
          "application/stl",
        ].includes(type)
      : false;

  const allowUpload = useMemo(
    () => (uploadReadyUri?.convertedSize ?? size ?? 0) < MAX_MODEL_SIZE_BYTES,
    [size, uploadReadyUri]
  );

  const contentType: ValidUploadMimeType | undefined = useMemo(() => {
    if (uploadReadyUri?.convertedType) {
      return uploadReadyUri?.convertedType;
    }
    if (type === "model/gltf+json" || type === "model/gltf-binary") {
      return type;
    }
    return undefined;
  }, [type, uploadReadyUri]);

  useEffect(() => {
    if (!uri) return;
    setUpload(undefined);
    (async () => {
      try {
        const blob = await (await fetch(uri)).blob();
        setBlobUri(URL.createObjectURL(blob));
      } catch (err) {
        setBlobUri(null);
        console.warn("Local model preview has expired.");
        return;
      }
    })();
  }, [uri]);

  useEffect(() => {
    if (upload) {
      // Change to the public URL
      window.history.replaceState(
        "",
        "",
        `${window.location.origin}/models/${upload.model.model_id}`
      );
    }
  }, [upload]);

  const loaderFormat = type && LoaderTypes[type];

  const [controlPanelRef, setControlPanelRef] = useState<HTMLDivElement | null>(
    null
  );

  if (blobUri === undefined) return null;
  if (loaderFormat == null || uri == null) return null;

  if (!blobUri) {
    return (
      <ErrorFallBackView
        title="Preview Expired"
        message="The local preview of your model has expired. Please publish again."
      />
    );
  }

  return (
    <>
      <UpdateCheck />
      <ModelOptionsDialog
        type={type}
        open={requiresOptions && !modelOptions}
        onClose={setModelOptions}
      />
      {(modelOptions || !requiresOptions) && (
        <MarkersContextProvider key={uri}>
          <CaptureContextProvider>
            {uri && (
              <ModelViewLoader
                key={"modelviewloader_" + uri}
                uri={uri}
                modelOptions={modelOptions}
                onPreviewConverted={setUploadReadyUri}
                format={loaderFormat}
                modelId={upload?.model.model_id}
                uiDomElement={controlPanelRef}
                previewSize={size}
              />
            )}
            <Overlay
              ref={(r) => {
                setControlPanelRef(r);
              }}
            >
              {upload?.model && <QRModelCodeViewButton model={upload.model} />}

              {contentType && uploadReadyUri && !upload && (
                <Upload
                  key={"upload_" + uri}
                  uri={uploadReadyUri.uri}
                  contentType={contentType}
                  name={name}
                  onComplete={(model) => setUpload({ model: model })}
                  disabled={!allowUpload}
                />
              )}
              <MarkerOptionsButton />
            </Overlay>
          </CaptureContextProvider>
        </MarkersContextProvider>
      )}
    </>
  );
}

export function redirectToPreview(file: File, next: (uri: string) => any) {
  console.info("Received file", file);
  let type: ValidMimeType | undefined;
  let name: string | undefined;

  // Coerce mime from file.type
  const typeNum = ValidMimeTypes.indexOf(file.type as ValidMimeType);
  if (typeNum >= 0) {
    type = ValidMimeTypes[typeNum];
  }

  // Coerce mime from file extension (name)
  if (!type) {
    const ext = file.name
      .toLocaleLowerCase()
      .split(".")
      .reverse()[0] as ValidFileExtension;
    type = MimeTypes[ext];
  }

  if (!type) {
    alert("Invalid file type");
    console.error("Invalid file type", file);
    return;
  }

  const uri = window.URL.createObjectURL(file);
  if (file.name !== "") {
    if (file.name.includes(".")) {
      name = file.name.split(".")[0];
    } else {
      name = file.name;
    }
  }

  const size = file.size;

  const queryString = qs.parse(window.location.search);

  const query = {
    // Preserve as much of the previous query as possible
    ...queryString,
    uri,
    name,
    type,
    size,
  };

  console.info("Query", query);

  next(`/preview?${qs.stringify(query, { skipNull: true })}`);
}
