import configs from "config.json";
import queryString from "query-string";

interface FirebaseConfig {
  projectId: string;
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface Config {
  /**
   * App identifier used for deep linking, i.e. canary
   */
  protocol: string;
  /**
   * The name of the app, i.e. Canary
   */
  name: string;
  /**
   * Firebase initialization options
   */
  firebase: FirebaseConfig;
  /**
   * A url that can be used to give the user tools to print QR codes.
   * Must accept the format #qr=one,two,three
   */
  qrplotter: string;
  /**
   * The address of the api root. Does not include anything except the primary domain.
   */
  api: string;
  /**
   * If true, diagnostics should be reported
   */
  logToConsole: boolean;
  /**
   * True if running on localhost
   */
  runningLocally: boolean;
}

const stage = process.env.REACT_APP_STAGE;

const config: Config = {
  ...(stage === "production" ? configs.production : configs.development),
  runningLocally: window.location.hostname === "localhost",
};

// Enable console logging if #debug is in the hash
if (
  !config.logToConsole &&
  queryString.parse(window.location.hash).debug !== undefined
) {
  config.logToConsole = true;
}

export default config;

export const isHoloLens =
  // Check if the launch hash contains platform=hololens
  queryString.parseUrl(window.location.href).query.platform === "hololens" ||
  // Dirty check, edge running on ARM64.
  (window.navigator.userAgent.indexOf("Windows NT 10.0; ARM64") > -1 &&
    window.navigator.userAgent.indexOf("Edg") > -1);
