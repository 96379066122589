import { useEffect, useState } from "react";
import { CircularProgress, Fade, Typography, Box } from "@mui/material";

export default function SuspenseIndeterminate({
  message,
  fullscreen,
}: {
  message?: string;
  fullscreen?: boolean;
}) {
  const [visible, setVisible] = useState<boolean>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Fade in={visible} timeout={1000}>
      <Box
        component="div"
        sx={{ ...styles.container, ...(fullscreen && styles.fullscreen) }}
      >
        <CircularProgress />
        {message && (
          <Typography variant="caption" sx={styles.text}>
            {message}
          </Typography>
        )}
      </Box>
    </Fade>
  );
}

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
  },
  fullscreen: {
    height: "100vh",
  },
  text: {
    opacity: 0.5,
    margin: 1,
  },
} as const;
