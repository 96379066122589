import React from "react";
/**
 * MIME types that should be accepted by the file drop
 * Any empty string means unknown types (i.e. not image/jpeg or text/plan etc)
 */
const ACCEPT_TYPES = [""];

/**
 * Returns true if the user is performing a drag operation.
 */
export default function useFileDrop(callback: (files: File[]) => any) {
  const dragCountRef = React.useRef<number>(0);
  const [dragging, setDragging] = React.useState<boolean>(false);
  const [dragValid, setDragValid] = React.useState<boolean>(true);

  // Window wide event listener so that we can increase the dropzone size when the user has a file ready
  React.useEffect(() => {
    const onDragEnter = (ev: DragEvent) => {
      ev.preventDefault();
      ev.stopPropagation();

      setDragValid(
        (() => {
          if (ev.dataTransfer?.items) {
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
              const itm = ev.dataTransfer.items[i];
              if (!ACCEPT_TYPES.includes(itm.type)) {
                return false;
              }
            }
          }
          return true;
        })()
      );

      dragCountRef.current++;
      if (dragCountRef.current === 1) {
        setDragging(true);
      }
    };

    const onDragEnd = (ev: Event | DragEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      dragCountRef.current--;
      if (dragCountRef.current <= 0) setDragging(false);
    };

    const onDrop = (ev: DragEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      onDragEnd(ev);

      const files: File[] = [];
      if (ev.dataTransfer?.items) {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          const itm = ev.dataTransfer.files[i];
          if (!ACCEPT_TYPES.includes(itm.type)) {
            return; // break early
          }
          files.push(itm);
        }
      }
      if (files.length > 0) {
        callback(files);
      }
    };

    const preventDefault = (ev: Event) => {
      ev.preventDefault();
    };

    const elem = window;
    elem.addEventListener("dragenter", onDragEnter, false);
    elem.addEventListener("dragleave", onDragEnd, false);
    elem.addEventListener("drop", onDrop, false);
    elem.addEventListener("dragend", onDragEnd, false);
    window.addEventListener("dragover", preventDefault, false);
    window.addEventListener("drop", preventDefault, false);

    return () => {
      elem.removeEventListener("dragenter", onDragEnter, false);
      elem.removeEventListener("dragleave", onDragEnd, false);
      elem.removeEventListener("drop", onDrop, false);
      elem.removeEventListener("dragend", onDragEnd, false);
      window.removeEventListener("dragover", preventDefault, false);
      window.removeEventListener("drop", preventDefault, false);
    };
  }, [dragCountRef, callback]);

  return {
    isDragging: dragging,
    isDragValid: dragValid,
  };
}
