import { AccountCircle } from "@mui/icons-material";
import { Button, Box } from "@mui/material";
import { useState } from "react";
import { AuthDialog } from "../Auth";

/**
 * Pure component for sign in area
 */
export default function SignIn({
  message,
  onSuccess,
  ...other
}: {
  message?: string;
  onSuccess?: () => any;
}) {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <AuthDialog
        open={visible}
        onCancel={() => setVisible(false)}
        onSuccess={onSuccess}
      >
        Sign in to your Twinbuild account
      </AuthDialog>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setVisible(true)}
        startIcon={<AccountCircle />}
        {...other}
      >
        <Box component="div" sx={{ width: "100%" }}>
          {message ?? "Sign In"}
        </Box>
      </Button>
    </>
  );
}
