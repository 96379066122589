import { Fade, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CloudUpload } from "@mui/icons-material";
import useFileDrop from "hooks/useFileDrop";
import { redirectToPreview } from "View/PreviewModelViewer";
import { useAlerts } from "Alerts";
import firebase from "firebase/compat/app";

const analytics = firebase.analytics();

/**
 * Global and window-wide drag and drop handler
 */
export default function Drop() {
  const navigate = useNavigate();
  const { alert } = useAlerts();

  async function onFiles(files: File[]) {
    if (files.length > 1) {
      console.error("Drop received multiple files");
      await alert("Only one file at a time is accepted");
      return;
    }

    const file = files[0];
    analytics.logEvent("Drop File", {
      type: file.type,
      size: file.size,
      name: file.name,
    });

    redirectToPreview(file, (uri) => navigate(uri));
  }

  const { isDragging } = useFileDrop(onFiles);

  return <DropView isDragging={isDragging} />;
}

export function DropView({ isDragging }: { isDragging: boolean }) {
  return (
    <Box component="div" sx={styles.fade} id="drop-container">
      <Fade in={isDragging}>
        <Box component="div" sx={styles.container} tabIndex={-1}>
          <CloudUpload sx={styles.icon} />
          <Typography variant="h4" sx={styles.title}>
            glTF, IFC, OBJ, FBX or PLY
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
}

const styles = {
  container: {
    opacity: 1,
    backgroundColor: "primary.main",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  },
  fade: {
    zIndex: 1000,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    pointerEvents: "none",
  },
  title: {
    color: "white",
  },
  icon: {
    color: "white",
    width: 72,
    height: 72,
  },
} as const;
