import "@react-three/fiber";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  ButtonGroup,
  Select,
  MenuItem,
  Box,
  Link,
} from "@mui/material";
import { Add, FilterCenterFocus, FlipToBack, Print } from "@mui/icons-material";
import { useMarkers } from "./useMarkers";
import { Model, ModelOrientationMode } from "Types";
import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import config from "config";
import { useAlerts } from "Alerts";
import QRTagImporter from "./MarkerImportView";

const db = firebase.firestore();

export default function MarkerOptionsView({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => any;
}) {
  const { addMarker, markers, model } = useMarkers();
  const fullScreen = useMediaQuery("(max-width:600px)");
  const { alert } = useAlerts();

  const [importerVisible, setImporterVisible] = useState<boolean>(false);

  const showPrintDialog = useCallback(() => {
    if (markers.length === 0) {
      alert(
        "You'll need to add or import Markers before you're able to print them.",
        "No Markers"
      );
      return false;
    }
    printCodes(markers.map((m) => m.code.data));
    return true;
  }, [markers, alert]);

  return (
    <>
      <QRTagImporter
        open={importerVisible}
        onClose={() => setImporterVisible(false)}
        modelId={model?.model_id}
      />
      <Dialog
        open={open}
        onClose={onClose}
        keepMounted={true}
        fullScreen={fullScreen}
      >
        <DialogTitle>Markers</DialogTitle>
        <DialogContent>
          <Box component="div" sx={styles.caption}>
            <Typography variant="caption">
              Markers are QR codes that map from digital to physical space.
              Markers allow accurate hologram placement in the real world. Add
              Markers as required, print them off, and match their physical
              positions on-site. Coordinates are in meters.
            </Typography>
          </Box>
          <ButtonGroup variant="outlined" color="primary" sx={styles.group}>
            <Button
              sx={styles.buttonContainer}
              onClick={() => {
                onClose();
                setImporterVisible(true);
              }}
            >
              <Box component="div" sx={styles.button}>
                <FlipToBack fontSize="large" />
                Import
              </Box>
            </Button>
            <Button
              sx={styles.buttonContainer}
              onClick={() => {
                if (showPrintDialog()) {
                  onClose();
                }
              }}
            >
              <Box component="div" sx={styles.button}>
                <Print fontSize="large" />
                Print
              </Box>
            </Button>
            <Button
              sx={styles.buttonContainer}
              onClick={() => {
                onClose();
                addMarker();
              }}
            >
              <Box component="div" sx={styles.button}>
                <Add fontSize="large" />
                Add
              </Box>
            </Button>
          </ButtonGroup>
          <OrientationModeView />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function OrientationModeView() {
  const { model } = useMarkers();

  // When we transition from preview to model
  // We need to ensure we set the orientation mode
  const [orientationMode, setOrientationMode] = useState<ModelOrientationMode>(
    model?.orientation_mode ?? "zup"
  );

  const [modelOrientationMode, setModelOrientationMode] = useState<
    ModelOrientationMode | undefined
  >(model?.orientation_mode);

  // Mirror local state to firestore if we are connected
  useEffect(() => {
    if (orientationMode !== modelOrientationMode && model != null) {
      (async () => {
        const update: Partial<Model> = {
          orientation_mode: orientationMode,
        };
        await db.doc(`models/${model.model_id}`).update(update);
        setModelOrientationMode(orientationMode);
      })();
    }
  }, [orientationMode, modelOrientationMode, model]);

  return (
    <Box component="div" sx={styles.orientationModeContainer}>
      <Typography variant="h6" gutterBottom>
        Orientation
      </Typography>
      <Typography variant="caption" sx={styles.orientationCaption}>
        Orientation determines the technique used to accurately place your model
        on site.
      </Typography>
      <Select
        variant="outlined"
        size="small"
        value={orientationMode}
        onChange={(ev) => {
          setOrientationMode(ev.target.value as any);
        }}
      >
        <MenuItem value={"zup"}>
          <Typography variant="body2">Orient to Site</Typography>
        </MenuItem>
        <MenuItem value={"threebody"}>
          <Typography variant="body2">Orient to Object</Typography>
        </MenuItem>
      </Select>
      <Typography variant="caption" sx={styles.orientationCaption}>
        {orientationMode === "zup" &&
          "Orient to Site should be used when the physical location of markers does not change. " +
            "The hologram is free to rotate around the vertical axis. " +
            "This mode is best when markers are placed on the surrounding site. "}
        {orientationMode === "threebody" &&
          "Orient to Object should be used when the orientation of the workpiece changes. " +
            "The hologram is free to rotate on all axis. " +
            "This mode is best when markers are placed on the workpiece. "}
        <Link
          href="https://docs.twinbuild.com/user-experience/model-orientation.html#choosing-an-appropriate-model-orientation-for-your-application"
          target="_blank"
        >
          Learn More.
        </Link>
      </Typography>
    </Box>
  );
}

const styles = {
  caption: {
    margin: "4px",
    marginTop: 0,
    marginBottom: "24px",
  },
  buttonContainer: {
    display: "flex",
    flexGrow: 1,
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    width: "100%",
  },
  group: {
    width: "100%",
  },
  orientationModeContainer: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  orientationCaption: {
    marginTop: "12px",
    marginBottom: "12px",
  },
} as const;

export function MarkerOptionsButton() {
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);

  return (
    <>
      <MarkerOptionsView
        open={optionsVisible}
        onClose={() => setOptionsVisible(false)}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setOptionsVisible(true);
        }}
        startIcon={<FilterCenterFocus />}
        style={{
          width: "100%",
          minWidth: 150,
        }}
      >
        <Box component="div" sx={{ width: "100%" }}>
          Markers
        </Box>
      </Button>
    </>
  );
}

export function printCodes(datas: string[]) {
  window.open(
    `${config.qrplotter}#qr=${datas
      .map((data) => encodeURIComponent(data))
      .join(",")}`
  );
}
